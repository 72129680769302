import React from 'react';
import styled from 'styled-components';

import { device } from '../../utilities/breakpoints';

export default class OtherActivities extends React.Component {
	render () {
		const otherActivitiesTextBuaStord = ((this.props.componentText || {}).buaStord || {});
		const otherActivitiesTextBuaBomlo = ((this.props.componentText || {}).buaBomlo || {});
		const otherActivitiesTextOpplevBomlo = ((this.props.componentText || {}).opplevBomlo || {});
		const otherActivitiesTextJoin = ((this.props.componentText || {}).join || {});
		const otherActivitiesTextFritidskassa = ((this.props.componentText || {}).fritidskassa || {});
		const otherActivitiesBomloFrikort = ((this.props.componentText || {}).bomloFrikort || {});
		const otherActivitiesFitjarFritidskasse = ((this.props.componentText || {}).fitjarFritidskasse || {});
		return (
			<Wrapper>
				<span id="skip-other"></span>
				{this.props.componentText.header}
				<Container>
					<ArticleWrapperLink
						href="https://www.bua.io/bua-stord/utstyr"
						className="nolink other-activities"
						target="_blank"
						rel="noreferrer"
						title='Opne i ny fane'
					>
						<div>
							<Header>{otherActivitiesTextBuaStord.header}</Header>
							{otherActivitiesTextBuaStord.text}
						</div>
					</ArticleWrapperLink>
					<ArticleWrapperLink
						href="https://www.bua.io/bua-bomlo/utstyr"
						className="nolink other-activities"
						target="_blank"
						rel="noreferrer"
						title='Opne i ny fane'
					>
						<div>
							<Header>{otherActivitiesTextBuaBomlo.header}</Header>
							{otherActivitiesTextBuaBomlo.text}
						</div>
					</ArticleWrapperLink>
					<ArticleWrapperLink
						href="https://opplevbomlo.no/"
						className="nolink other-activities"
						target="_blank"
						rel="noreferrer"
						title='Opne i ny fane'
					>
						<div>
							<Header>{otherActivitiesTextOpplevBomlo.header}</Header>
							{otherActivitiesTextOpplevBomlo.text}
						</div>
					</ArticleWrapperLink>
					<ArticleWrapperLink
						href="https://www.stord.kommune.no/join.573531.nn.html"
						className="nolink other-activities"
						target="_blank"
						rel="noreferrer"
						title='Opne i ny fane'
					>
						<div>
							<Header>{otherActivitiesTextJoin.header}</Header>
							{otherActivitiesTextJoin.text}
						</div>
					</ArticleWrapperLink>
					<ArticleWrapperLink
						href="https://www.stord.kommune.no/fritidskasse.578708.nn.html"
						className="nolink other-activities"
						target="_blank"
						rel="noreferrer"
						title='Opne i ny fane'
					>
						<div>
							<Header>{otherActivitiesTextFritidskassa.header}</Header>
							{otherActivitiesTextFritidskassa.text}
						</div>
					</ArticleWrapperLink>
					<ArticleWrapperLink
						href="https://www.bomlo.kommune.no/tenester/kultur-idrett-og-frivilligheit/fritidskortet/"
						className="nolink other-activities"
						target="_blank"
						rel="noreferrer"
						title='Opne i ny fane'
					>
						<div>
							<Header>{otherActivitiesBomloFrikort.header}</Header>
							{otherActivitiesBomloFrikort.text}
						</div>
					</ArticleWrapperLink>
					<ArticleWrapperLink
						href="https://www.fitjar.kommune.no/fritidskasse.561689.nn.html"
						className='nolink other-activities'
						target="_blank"
						rel="noreferrer"
						title='Opne i ny fane'
					>
						<div>
							<Header>{otherActivitiesFitjarFritidskasse.header}</Header>
							{otherActivitiesFitjarFritidskasse.text}
						</div>
					</ArticleWrapperLink>
				</Container>
			</Wrapper>
		);
	}
}

const Wrapper = styled.section`
    	margin: 4em auto 0;
		padding: 0 2em;
		display: flex;
		flex-direction: column;

        @media ${device.mobile} {
            padding: 0 3em;
        }
    `;
const Container = styled.div`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 1em;
        grid-row-gap: 1em;

        @media ${device.tablet} {
            display: flex;
            flex-direction: column;

            &:last-child {
                margin-bottom: 4em;
            }
        }
    `;
const ArticleWrapperLink = styled.a`
		background-color: #fff;
		border-radius: 0.75em;
		padding: 1em;
		border: 1px solid #000;
		transition: background-color 250ms ease;

		&:hover {
			background-color: #AADAA9;	
		}
	`;
const Header = styled.h2`
        margin-bottom: 0;
    `;
