import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/pro-light-svg-icons';

import logo from '../assets/images/logo.svg';
import { device } from '../utilities/breakpoints';
import Modal from './Activities/OrgModal';
import { urlDev } from '../utilities/devEnv';

export default class Nav extends React.Component {
	constructor (props) {
		super(props);

		this.state = {
			language: false,
		};

		this.handleClickLanguage = this.handleClickLanguage.bind(this);
	}

	handleClickLanguage () {
		const languageEnabled = this.state.language;
		this.setState({ language: !languageEnabled });
	}

	render () {
		const modalText = this.props.text?.languageSelect?.textContent;

		const renderLanguageModal = () => {
			if (this.state.language) {
				return (
					<Modal show={this.state.language} handleClose={this.handleClickLanguage}>
						<ModalContent>
							<p lang='en'>{modalText}</p>
						</ModalContent>
					</Modal>
				);
			}
		};

		return (
			<Header>
				<Wrapper>
					<Link to={`${urlDev}`} className="img-btn" title='Gå til framside'>
						<Image src={logo} alt="Aktivitet for alle logo" />
					</Link>
				</Wrapper>
				<NavWrapper>
					<LangBtn
						onClick={this.handleClickLanguage}
						title='Les meir om oversetting av nettstedet'
					>
						<FontAwesomeIcon icon={faLanguage} />
					</LangBtn>
				</NavWrapper>
				{renderLanguageModal()}
			</Header>
		);
	}
}

const Header = styled.div`
	`;
const Wrapper = styled.nav`
		max-width: 1600px;
		margin: 0 auto;

		a {
			width: fit-content;
		}

		@media ${device.mobile}{
			margin: 0;
			left: 0px;
		}
    `;
const Image = styled.img`
		width: auto;
		height: 150px;
		position: relative;

		@media ${device.laptop} {
			position: unset;
			left: unset;
		}

		@media ${device.mobile}{
			height: 100px;
		}

		@media ${device.smMobile} {
			height: 50px;
		}
    `;
const LangBtn = styled.button `
		position: absolute;
		right: 90px;
		top: 1em;
		background-color: transparent;

		&:dir(rtl){
			left: 0;
			right: unset;
		}

		svg {
			font-size: 1.75em;
		}

		@media ${device.laptop} {
			right: 30px;
			&:dir(rtl){
				left: 0;
				right: unset;
			}
		}
		@media ${device.mobile} {
			right: 10px;
			&:dir(rtl){
				left: 0;
				right: unset;
			}
		}
    `;
const NavWrapper = styled.div `
    `;
const ModalContent = styled.div`
		padding: 3em 3em 5em;
	`;