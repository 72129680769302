import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

import { device } from '../../utilities/breakpoints';

const Search = ({ searchIsActive, showSearch, handleSearch, componentText }) => {
	const placeholderText = (componentText.search || {}).placeholder;
	return (
		<SearchWrapper>
			<label htmlFor="search">
				<span className="visually-hidden">{placeholderText}</span>
			</label>
			<SearchBar
				type="text"
				id="search"
				placeholder={placeholderText}
				onInput={handleSearch}
			/>
			<button
				type="button"
				className={'nobutton search-button'}
				value={searchIsActive}
				onClick={showSearch}
				title='Søk'
			>
				<FontAwesomeIcon icon={faSearch} />
			</button>
		</SearchWrapper>
	);
};

export default Search;

const SearchWrapper = styled.section`
        display: flex;
        align-items: center;
        width: 100%;
    `;
const SearchBar = styled.input`
        font-size: 1.5em;
        padding: 0.5em;
        width: 100%;
        border: unset;
        border-bottom: 1px solid #999;

        @media ${device.mobile} {
            margin: 0 0 0 3em;
        }

		@media ${device.smMobile}{
			margin: 0;
		}
    `;
