import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/pro-light-svg-icons';
import React, { useEffect } from 'react';

const EventsNavigation = ({ 
	currentMonth, 
	setCurrentMonth, 
	currentYear, 
	setMaxPage,
	setCurrentYear, 
	setCurrentEvents, 
	sortedEvents, 
	filteredEvents,
	currentDate,
	children 
}) => {
	const getMonthRange = () => {
		let currentEvents =  [];
		
		if (filteredEvents === null) {
			currentEvents = getEventsByMonth(sortedEvents, currentMonth, currentYear);
		} else {
			currentEvents = getEventsByMonth(filteredEvents, currentMonth, currentYear);
		}

		// Handle pagination limits
		const perPage = 6;
		const maxPage = Math.ceil(currentEvents.length / perPage );

		setMaxPage(maxPage);
		setCurrentEvents(currentEvents);	
	};	
	// Only show events from today.
	const fromCurrentDate = (arr) => {
		const fromToday = arr.filter(event => {
			if (event.start >= currentDate.getTime() / 1000) {
				return event;
			}
		});
			
		return fromToday;
	};

	const getEventsByMonth = (events, month, year) => {
		const monthEvents =  events.filter(event => {
			if (event.month === month && event.year === year) {
				return event;
			}
		});
		return fromCurrentDate(monthEvents);
	};

	const nextMonth = () => {
		const date = new Date();

		if (currentYear === date.getFullYear() + 2) {
			return;
		}
		if (currentMonth === 12) {
			setCurrentMonth(1);
			setCurrentYear(currentYear + 1);
			return;
		}
		setCurrentMonth(currentMonth + 1);
	};
	const prevMonth = () => {
		const date = new Date();

		if (currentYear === date.getFullYear() - 2) {
			return;
		}
		if (currentMonth === 1) {
			setCurrentMonth(12);
			setCurrentYear(currentYear - 1);
			return; 
		}
		setCurrentMonth(currentMonth - 1);
	};

	const renderMonthNav = () => {
		const months = ['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'];

		return (
			<div className='event-navigation'>
				<button 
					onClick={() => prevMonth()}
				>
					{<FontAwesomeIcon icon={faChevronLeft} />}
				</button>
				<h2>{months[currentMonth - 1]} {currentYear}</h2>
				<button
					onClick={() => nextMonth()}
				>
					{<FontAwesomeIcon icon={faChevronRight} />} 
				</button>
			</div>
		);
	};

	useEffect(() => {
		getMonthRange();
	}, [currentMonth, currentYear, filteredEvents]);

	return (
		<div>
			{renderMonthNav()}
			{children}
		</div>
	);
};

export default EventsNavigation;