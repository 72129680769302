import React from 'react';
import { Link } from 'react-router-dom';
import { urlDev } from '../utilities/devEnv';

const NotFound = () => {
	return (
		<div id='main-content' style={{ margin: ' 22.5em 3em' }}>
			<h2>404 - Found Nothing</h2>
			<Link to={`${urlDev}`}>Gå tilbake til framsiden.</Link>
		</div>
	);
};

export default NotFound;
