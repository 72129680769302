import React, { useEffect } from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import nn from 'date-fns/locale/nn';

registerLocale('nn', nn);

const AdminAreaFilters = ({ 
	filters, 
	setFilters, 
	handleFilters,
	handleReset, 
	locations, 
	setLocations, 
	fromDate,
	setFromDate,
}) => {

	const handleDropdown = (e) => {
		const target = e.target;
		setFilters({...filters, ['type']: target.value});	
	};

	const handleLocation = (e) => {
		const target = e.target;

		if (locations.indexOf(target.value) !== -1) {
			const newArr = locations.filter(location => {
				return location !== target.value;
			});
			setLocations(newArr);
			return;
		}

		setLocations(prevState => [...prevState, target.value]);
	};

	useEffect(() => {
		setFilters({...filters, ['locations']: locations});
	}, [locations]);

	useEffect(() => {
		setFilters({...filters, ['date']: fromDate});
	}, [fromDate]);

	return (
		<div className='admin-filter-form'>
			<form onSubmit={(e) => {handleFilters(e);}}>
				<section className='filters filter-locations'>
					<div>
						<input onChange={(e) => {handleLocation(e);}} value='bomlo' type='checkbox' id='bomlo'/>
						<label htmlFor='bomlo'>Bomlo</label>
					</div>
					<div>
						<input onChange={(e) => {handleLocation(e);}} value='fitjar' type='checkbox' id='fitjar'/>
						<label htmlFor='fitjar'>Fitjar</label>
					</div>
					<div>
						<input onChange={(e) => {handleLocation(e);}} value='stord' type='checkbox' id='stord'/>
						<label htmlFor='stord'>Stord</label>
					</div>
				</section>
				<section className='filters filter-type'>
					<select id='type' value={filters.type} onChange={(e) => {handleDropdown(e);}}>
						<option value="" disabled defaultValue>Velg Type:</option>
						<option value='fb'>Facebook</option>
						<option value='af'>Aktiv fritid</option>
						<option value='eb'>E-billett</option>
					</select>
				</section>
				<section className='filters filter-date'>
					<DatePicker
						selected={fromDate}
						onChange={(date) => setFromDate(date)}
						dateFormat='dd-MM-yyyy'
						locale='nn'
						isClearable
						placeholderText="Når?"
					/>
				</section>
				<div className='filters filter-do'>
					<button type='submit' title='Bruk filtre'>Bruk filter</button>
					<button type='button' title='Tøm filtre' onClick={() => {handleReset();}}>Tøm filter</button>
				</div>
			</form>
		</div>
	);
};

export default AdminAreaFilters;