import React, { useState, useEffect } from 'react';

import { dev } from '../../utilities/devEnv';

const ChangePassword = () => {
	const [feedback, setFeedback] = useState('');
	const [isError, setIsError] = useState(true);

	const handleSubmit = (e) => {
		e.preventDefault();

		const url = `${dev}/auth/action/change_password`;

		const username = document.getElementById('change-pass-name').value;
		const existingPass = document.getElementById('existingPass').value;
		const newPass = document.getElementById('newPass').value;

		const data = new FormData();
		data.append('username', username);
		data.append('existing_password', existingPass);
		data.append('new_password', newPass);

		fetch(url, {
			method: 'POST',
			headers: {
				'x-api-key': '071bb2abbfb9d54bb7f71da320183e'
			},
			body: data
		})
			.then(res => res.json())
			.then((data) => {
				if (data.code === 200) {
					setFeedback(data.feedback);
					setIsError(data.error);
				}
			});
	};

	useEffect(() => {
		if (feedback) {
			setTimeout(() => {
				setFeedback('');
			}, 4000);
		}

		return () => {
			if (feedback) {
				setFeedback('');
			}
		};

	}, [feedback]);

	const passMatches = () => {
		const newPass = document.getElementById('newPass').value;
		const confirmPass = document.getElementById('confirmPass').value;

		if (newPass !== confirmPass) {
			setFeedback('Dei nye passordene samsvarar ikkje.');
		}
	};

	return (
		<div className='settings-change-password'>
			<h1>Endre passord</h1>
			<p className={`settings-feedback ${isError ? 'error' : 'success'}`}>{feedback}</p>
			<form onSubmit={(e) => {handleSubmit(e);}}>
				<div>
					<label htmlFor='change-pass-name'>Brukernamn: </label>
					<input required id='change-pass-name' name='username' type='text' autoComplete='username' />
				</div>
				<div>
					<label htmlFor='existingPass'>Eksisterende passord: </label>
					<input required id='existingPass' name='curPass' type='password' autoComplete='current-password' />
				</div>
				<div>
					<label htmlFor='newPass'>Nytt passord: </label>
					<input required id='newPass' name='newPass' type='password' onChange={() => passMatches()} autoComplete='new-password' />
				</div>
				<div>
					<label htmlFor='confirmPass'>Bekreft passord: </label>
					<input required id='confirmPass' name='confirmPass' type='password' onChange={() => passMatches()} autoComplete='new-password' />
				</div>
				<button
					type='submit'
				>
					<p>Endre passord</p>
				</button>
			</form>
		</div>
	);
};

export default ChangePassword;