import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import { EventContextProvider } from './context/EventContext';

ReactDOM.render(
	<React.StrictMode>
		<EventContextProvider>
			<App />
		</EventContextProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

const ids = ['main-content', 'skip-events', 'skip-categories', 'skip-other'];
ids.forEach(id => {
	const target = document.querySelector('#' + id);

	if (target) {
		const skipnav = document.querySelector('#skipnav');
		const p = document.createElement('p');
		p.classList.add('skipnav');
		
		const a = document.createElement('a');
		a.href = '#' + id;

		switch(id) {
			case 'main-content':
				a.appendChild(document.createTextNode('Gå til hovedinnhald ( Trykk Enter )'));
				break;
			case 'skip-events':
				a.appendChild(document.createTextNode('Gå til arrangement ( Trykk Enter )'));
				break;
			case 'skip-categories':
				a.appendChild(document.createTextNode('Gå til aktiviteter ( Trykk Enter )'));
				break;
			case 'skip-other':
				a.appendChild(document.createTextNode('Gå til andre ressurser ( Trykk Enter )'));
				break;
		}

		p.appendChild(a);

		skipnav.appendChild(p);
	}

});
