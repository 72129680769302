import React from 'react';

const Loader = ({ message }) => {
	return (
		<div>
			{message}
		</div>
	);
};

export default Loader;