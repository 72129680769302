import React from 'react';

import { localDate } from '../../utilities/localDate';

const AdminOrganizerEventItem = (props) => {

	const renderEvent = () => {
		let title, date, time;

		switch (props.event.app_type) {
			case 'eb':
				title = props.event.title;
				date = new Date(props.event.start * 1000);
				time = new Date(props.event.start * 1000);
				date = localDate(date, 'date');
				time = time.toLocaleTimeString('nn-NO', {hour: 'numeric', minute: 'numeric' });
				break;
			case 'fb':
				title = props.event.app_name;
				date = new Date(props.event.app_start_time * 1000);
				time = new Date(props.event.app_start_time * 1000);
				date = localDate(date, 'date');
				time = localDate(time, 'time');
				break;
			case 'af':
				date = new Date(props.event.start * 1000);
				time = new Date(props.event.start * 1000);
				title = props.event.titles[0].value;
				date = localDate(date, 'date');
				time = localDate(time, 'time');
				break;
		}

		let eventId = '';
		if (props.event.app_type === 'fb') {
			eventId = props.event.event_id + '';
		} else if (props.event.app_type === 'eb') {
			eventId = props.event.id + '';
		} else if (props.event.app_type === 'af') {
			eventId = props.event.id + '';
		}

		const setEventExcluded = (event, eventId) => {
			if (props.excludedEvents.length) {
				if (props.excludedEvents.includes(eventId)) {
					event.is_excluded = true;
				} else {
					event.is_excluded = false;
				}
			} else {
				event.is_excluded = false;
			}
		};

		setEventExcluded(props.event, eventId);

		return (
			<React.Fragment key={eventId}>
				<td>{title}</td>
				<td>{`${date} - kl.: ${time}`}</td>
				<td className='admin-event-btn-wrapper'>
					{ !props.event.is_excluded ?
						<button 
							className='exclude-btn table-btn'
							type='button'
							onClick={() => props.handleExclusion('exclude', eventId)}
							title='Skjul arrangement'
						>
							Skjul
						</button>
						:
						<button
							className='display-btn table-btn'
							type='button'
							onClick={() => props.handleExclusion('show', eventId)}
							title='Vis arrangement'
						>
							Vis
						</button>
					}
				</td>
			</React.Fragment>
		);
	};

	return renderEvent();
};

export default AdminOrganizerEventItem;