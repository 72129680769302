import React, { useState, useEffect } from 'react';
import AdminArea from '../components/AdminArea/AdminArea';
import data from '../utilities/data';
import { dev } from '../utilities/devEnv.js';
import Loader from '../components/Loader.js';
import SettingsArea from '../components/Settings/SettingsArea';

const Admin = ({ isAuthenticated, setAuthenticated, loading }) => {
	const [feedback, setFeedback] = useState('');
	const [settings, setSettings] = useState(false);

	useEffect(() => {
		render();
	}, [isAuthenticated]);

	const renderAdminArea = () => {
		return (
			<AdminArea 
				setAuthenticated={setAuthenticated}
				setFeedback={setFeedback}
				feedback={feedback}
				isAuthenticated={isAuthenticated}
				settings={settings}
				setSettings={setSettings}
			/>
		);
	};

	const render = () => {
		if (loading) {
			return <Loader />;
		} else {
			if (!settings) {
				return renderAdminArea(); 
			} else {
				return (
					<SettingsArea
						setSettings={setSettings}
						settings={settings}
					/>
				);
			}
		}
	};

	return render();
};

export default Admin;