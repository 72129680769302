import React from 'react';
import styled from 'styled-components';

import Categories from '../components/Activities/Categories';
import Events from '../components/Events/Events';
import OtherActivities from '../components/AddActivities/OtherActivities';
import AddActivities from '../components/AddActivities/AddActivities';
import headerImg from '../assets/images/Frontpage-img.svg';
import { device } from '../utilities/breakpoints';
import { SEO } from '../components/Seo';

export default class Home extends React.Component {
	constructor (props) {
		super(props);

		this.handleClickModal = this.handleClickModal.bind(this);
		this.handleCountyChange = this.handleCountyChange.bind(this);
		this.resetCounty = this.resetCounty.bind(this);

		this.state = {
			enabled: false,
			selectedCounty: null,
		};
	}

	handleClickModal () {
		const enabled = this.state.enabled;
		this.setState({ enabled: !enabled });
	}

	handleCountyChange (selectedCounty) {
		this.setState({ selectedCounty: selectedCounty });
	}

	resetCounty () {
		this.setState({ selectedCounty: null });
	}

	render () {
		const pageText = ((this.props.pageText || {}).home || {});
		const categoryText = (this.props.componentText || {});
		const addActivitiesText = ((this.props.componentText || {}).addActivities || {});
		const otherActivitiesText = ((this.props.componentText || {}).otherActivities || {});

		return (
			<Wrapper id="main-content">
				<SEO title='Aktivitet for alle' location="/" type="WebPage" description="Finn ein fritidsaktivitet som passer for deg."/>
				<Header>
					<TextWrapper>
						{pageText.title}
						{pageText.content}
					</TextWrapper>
					<ImageWrapper>
						<Image src={headerImg} alt='' aria-hidden='true' />
					</ImageWrapper>
				</Header>
				<Events />
				<Categories
					categories={this.props.categories}
					allActivities={this.props.allActivities}
					componentText={categoryText}
					language={this.props.language}
				/>
				<AddActivities
					className="white-button big-btn nobutton"
					componentText={addActivitiesText}
				/>
				<OtherActivities
					componentText={otherActivitiesText}
				/>
			</Wrapper>
		);
	}
}

const Wrapper = styled.section`
		margin: 0 4em 4em;

		@media ${device.laptop}{
			margin: 0 4em 4em;
		}
        @media ${device.tablet} {
            margin: 0 3em 3em;
        }
		@media ${device.mobile}{
			margin: 0 2em 2em;
		}
    `;
const Header = styled.section`
		display: flex;
		align-items: center;
		justify-content: space-around;
		padding: 4em 0 0;

		@media ${device.laptop}{
			flex-direction: column;
			padding: 0;
		}
		@media ${device.mobile}{
			padding: 0;
		}
    `;
const TextWrapper = styled.article`
		width: 40em;
		min-width: 450px;
        margin-left: 90px;

		@media ${device.laptop}{
			width: unset;
			min-width: unset;
			order: 2;
			margin: 0 5em;
		}
		@media ${device.mobile}{
            margin: 0;
			padding: 0 3em;
		}
		@media ${device.smMobile} {
			padding: 0 1rem;
		}
    `;
const ImageWrapper = styled.div`
		width: 1000px;

		@media ${device.laptop}{
            width: 800px;
			order: 1;
		}
		@media ${device.mobile}{
			width: 375px;
		}
		@media ${device.smMobile} {
			width: 250px;
		}
    `;
const Image = styled.img`
	    margin: 1.25rem;

		@media ${device.smMobile} {
			margin: 0;
		}
    `;