import React, { useEffect, useState } from 'react';
import OrganizerAreaListItem from './OrganizerAreaListItem';
import { dev } from '../../utilities/devEnv';

const OrganizerArea = ({ handleLogout }) => {
	const [userEvent, setUserEvent] = useState([]);
	const [prevAppl, setPrevAppl] = useState([]);
	const [feedback, setFeedback] = useState({});

	const logoutFb = () => {
		handleLogout();
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const form = document.getElementById('fb-events');
		const data = new FormData(form);

		const url = `${dev}/events/action/get_fb_event`;

		fetch( url, {
			method: 'POST',
			body: data
		})
			.then(res => res.json())
			.then((data) => {
				if (data.code === 200) {
					if (data.error) {
						handleFeedback('Fant ingen arrangement med denne ID-en', false);
					} else if (data.feedback) {
						handleFeedback(data.feedback, false);
					} else {
						const event = data.event;
						handleFeedback('', false);
						setUserEvent(prevState => [{...prevState, event}]);
					} 
				}
			});
	};

	const renderEvents = () => {
		const events = userEvent.map(event => {
			return (
				<OrganizerAreaListItem 
					key={event.event.id} 
					event={event.event}
					prevAppl={renderPreviousApplications} 
					setUserEvent={setUserEvent}
					handleFeedback={handleFeedback}
				/>
			);
		});
		return events;
	};

	const renderPreviousApplications = () => {
		const url = `${dev}/events/action/get_fb_events_by_user`;

		fetch(url, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		})
			.then(res => res.json())
			.then((data) => {
				if (data.code === 200) {
					const events = data.events.map(event => {
						// Make date readable
						const date = new Date(event.app_start_time * 1000);

						let endDateString = '';
						const endDate = event.app_end_time ? new Date(event.app_end_time * 1000) : '';
						if (endDate) {
							endDateString = `- ${endDate.toLocaleDateString()} ${endDate.toLocaleTimeString()}`;
						}

						// Handle event application status
						const status = () => {
							switch(event.app_status) {
								case 'approved':
									return 'Godkjent';
								case 'rejected':
									return 'Avvist';
								default:
									return 'Venter godkjenning';
							}
						};

						return (
							<li key={`prev-${event.event_id}`} className="prev-event-appl">
								<h3>{event.app_name}</h3>
								<span>
									<p>Beskrivelse: </p>
									<p>{event.app_description}</p>
								</span>
								<span>
									<p>Sted: </p>
									<p>{event.app_place_name}</p>
								</span>
								<span>
									<p>Tid: </p>
									<p>{`${date.toLocaleDateString()} ${date.toLocaleTimeString()} ${endDateString ? endDateString : endDate}`}</p>
								</span>
								<span>
									<p>Status: </p>
									<p>{status()}</p>
								</span>
							</li>
						);
					});

					setPrevAppl(events);
				}
			});
	};

	useEffect(() => {
		renderPreviousApplications();
	}, []);

	const handleFeedback = (message, bool) => {
		let color;

		if (bool) {
			color = '#159B41';
		} else {
			color = '#F32013';
		}

		const obj = {
			'message': message,
			'color': color
		};
		setFeedback(obj);
	};

	return (
		<div className='page organizer-page'>
			<div className='organizer-header'>
				<div>
					<h1>Arrangør Område</h1>
					<p>Her kan du legge inn forespørsel, om å legge til ditt facebook arrangement.</p>
				</div>
				<button 
					onClick={() => {logoutFb();}}
					className='logout-fb'
					title='Logg ut'
				>
					<p>Logg ut</p>
				</button>
			</div>
			<div className='fb-event-form'>
				<form id="fb-events" onSubmit={(e) => {handleSubmit(e);}}>
					<label htmlFor='event-id'>Facebook Event Id: </label>
					<input 
						type="text"
						name="event-id"
						id="event-id"
						required={true}
					/>
					<button type='submit'>
						<p>Hent arrangement</p>	
					</button>
				</form>
			</div>
			<div className='fb-event-current'>
				<h2>Legg til ny forespørsel om arrangement</h2>
				<p style={{color: feedback.color}}>{feedback.message}</p>
				<ul id="current-application">
					{
						renderEvents()
					}
				</ul>
			</div>
			<div className='fb-event-prev'>
				<h2>Tidligere arrangement forespørseler</h2>
				<ul id="previous-application">
					{ prevAppl }
				</ul>
			</div>
		</div>
	);
};

export default OrganizerArea;