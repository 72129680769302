import React from 'react';
import styled from 'styled-components';
import { localDate } from '../../utilities/localDate';
import { device } from '../../utilities/breakpoints';

const EventCard = ({ event }) => {
	const handleDate = () => {
		if (event.app_type === 'eb') {
			return localDate(event.begin, 'date');
		}

		if (event.isMultipleDays) {
			const start = [localDate(event.start, 'date')];
			const end = localDate(event.end, 'date');
			start.push(end);

			return start.join(' - ');
		} else {
			return localDate(event.start, 'date');
		}
		
	};

	const handleTime = () => {
		if (event.app_type === 'eb') {
			const date = new Date(event.start * 1000);
			const time = date.toLocaleTimeString('nn-NO', {hour: 'numeric', minute: 'numeric' });
			return time; 
		}
		if (event.start) {
			return localDate(event.start, 'time');
		}
	};

	const handleAddress = () => {
		if (event.app_type === 'eb') {
			return event.location.name;
		}

		if (event.geo) {
			const eventAddress = event.geo?.name;
			return eventAddress;
		}
	};

	const handlePrice = () => {
		if (event.app_type === 'eb') {
			return event.purchase_url;
		}

		if (event) {
			const eventPrice = event.totalPrice;
			if (eventPrice > 0) {
				return eventPrice;
			} else if (eventPrice === 0){
				return 'Gratis';
			} 
		}
	};

	const handleTitle = () => {
		if (event.app_type === 'eb') {
			const title = event.title;
			return title;
		}
		if (event.titles) {
			const title = event.titles[0]?.value;
			return title;
		}
	};

	return (
		<Card>
			<CardContent>
				<EventTitle>{handleTitle()}</EventTitle>
				<StartDate>{handleDate()} , kl. {handleTime()}</StartDate>
				<Address>{handleAddress()}</Address>
				{
					event.app_type === 'eb'
						? <PurchaseUrl href={handlePrice()}>Billettar</PurchaseUrl>
						:  event.totalPrice !== null ? <Price><span>Pris:</span> {handlePrice()}</Price> : null
				}
			</CardContent>
		</Card>
	);
};

export default EventCard; 

const Card = styled.div`
		color: #000;
    `;
const CardContent = styled.div`
        font-size: 1em;

		@media ${device.smMobile} {
			padding: 1rem;
        }
    `;
const EventTitle = styled.h1`
		font-size: 35px;
	`;
const StartDate = styled.p`
        font-size: 1em;
        margin: 0; 
		font-weight: 400;
    `;
const Address = styled.p`
		margin-top: 0;
		font-weight: 400;
    `;
const Price = styled.p`
        span {
            font-weight: 600;
        }
    `;
const PurchaseUrl = styled.a`
		color: #000;
		border: 1px solid #000;
		text-decoration: none;
		padding: 0.5rem 1rem;
		border-radius: 21px;
	`;