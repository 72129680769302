import React, { useEffect, useState } from 'react';

import SettingsOverview from './SettingsOverview';
import ChangePassword from './ChangePassword';
import CreateUser from './CreateUser';

const SettingsArea = ({ setSettings, settings }) => {

	const [currentSetting, setCurrentSetting] = useState('home');

	useEffect(() => {
		if (settings) {
			sessionStorage.setItem('settings', JSON.stringify(settings));
		}
		setSettings(true);

		return () => {
			sessionStorage.setItem('settings', JSON.stringify(false));
		};
	}, []);


	const handleCurrentSetting = (setting = '') => {
		switch(setting) {
			case 'password':
				setCurrentSetting('password');
				break;
			case 'createUser':
				setCurrentSetting('createUser');
				break;
			default:
				setCurrentSetting('home');
				break;
		}
	};

	const renderSettingComp = () => {
		switch(currentSetting) {
			case 'home':
				return <SettingsOverview />;
			case 'password':
				return <ChangePassword />;
			case 'createUser':
				return <CreateUser />;
		}
	};

	const gotoAdmin = () => {
		setSettings(false);
		sessionStorage.setItem('settings', JSON.stringify(false));
	};

	return (
		<section className='page page-settings'>
			<div className='settings admin-settings-toggle'>
				<button
					className='admin-toggle'
					onClick={() => gotoAdmin()}
					type='button'
					title='Gå tilbake'
				>
					<p>Tilbake</p>
				</button>
			</div>
			<div className='settings settings-content'>
				<nav>
					<button 
						onClick={() => {handleCurrentSetting();}}
						type='button'
						title='Gå til instillinger'
					>
						<p>Innstillinger</p>
					</button>
					<button 
						onClick={() => {handleCurrentSetting('password');}}
						type='button'
						title='Gå til å endre passord'
					>
						<p>Endre passord</p>
					</button>
					<button 
						onClick={() => {handleCurrentSetting('createUser');}}
						type='button'
						title='Gå til å lage ny bruker'
					>
						<p>Lag ny bruker</p>
					</button>
				</nav>
				{
					renderSettingComp()
				}
			</div>
		</section>
	);
};

export default SettingsArea;