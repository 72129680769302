import React from 'react';
import styled from 'styled-components';

// import logo from '../assets/images/logo-fritidskort.png'
import { device } from '../../utilities/breakpoints';

export default class Card extends React.Component {
	render () {
		const { content } = this.props;

		const filterAge = () => {
			const ageFrom = content.ageFrom;
			const ageTo = content.ageTo;
			let newAgeFrom = '';
			let newAgeTo = '';

			if (ageFrom !== null) {
				newAgeFrom = ageFrom + ' år';
			}

			if (ageTo !== null) {
				newAgeTo = ageTo + ' år';
			}

			return `${newAgeFrom} - ${newAgeTo}`;
		};

		const filterLocation = () => {
			const location = content && content.geo ? content.geo.name : null;

			return location;
		};

		const requiredEquipment = () => {
			const equipment = content && content.informations;
			let info;
			if (equipment) {
				info = equipment[0]?.equipment;
				return info;
			}
		};

		const renderLogo = () => {
			let logo = null;
			if (
				this.props.content &&
				'logoLink' in this.props.content &&
				this.props.content.logoLink.indexOf('LogoMangler') === -1
			) {
				logo = <CardLogo src={this.props.content.logoLink} alt='Aktivitet logo' />;
			}
			return <LogoWrapper>{logo}</LogoWrapper>;
		};

		const webPage = () => {
			const webPage = this.props.content.webPage;
			if (webPage) {
				return (
					<a
						target="_blank"
						rel='noreferrer'
						href={this.props.content.webPage}
					>
						{this.props.content.name}
					</a>
				);
			}
		};

		const totalPrice = () => {
			const price = this.props.content.totalPrice;
			if (price) {
				return price;
			} else {
				return 'Sjå tekstfelt';
			}
		};

		const cardText = this.props.componentText;

		return (
			<Wrapper>
				{renderLogo()}
				<ul className="nobullets">
					<li><Text><Span>{cardText.age}</Span>{filterAge()}</Text></li>
					<li><Text><Span>{cardText.place}</Span>{filterLocation()}</Text></li>
					<li><Text><Span>{cardText.price}</Span>{totalPrice()}</Text></li>
					<li><Text><Span>{cardText.equipment}</Span>{requiredEquipment()}</Text></li>
					<li><Text><Span>{cardText.webpage}</Span>{webPage()}</Text></li>
					<li><Text><Span>{cardText.phone}</Span>{this.props.content.phoneNumber}</Text></li>
					<li><Text><Span>{cardText.email}</Span><a href={`mailto:${this.props.content.emailAddress}`}>{this.props.content.emailAddress}</a></Text></li>
				</ul>
			</Wrapper>
		);
	}
}

const Wrapper = styled.aside`
	    background-color: #fff;
        border-bottom-right-radius: 50px;
        border-top-right-radius: 50px;
    	width: 505px;
        height: 640px;
	    padding: 0 2em;

      &:dir(rtl) {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 50px;
      }

      @media ${device.laptop} {
        border-top-right-radius: 50px;
        width: 405px;
        
        &:dir(rtl) {
            border-top-right-radius: 0;
            border-top-left-radius: 50px;
        }
      }

      @media ${device.mobile}{
	      width: unset;
          height: unset;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
	    }
    `;
const LogoWrapper = styled.div`
        display: flex;
        width: 100%;
        justify-content: right;
        padding: 1em 0 0;

        img {
            height: 150px;
            width: 150px;
        }
    `;
const CardLogo = styled.img`
      width: 100%;
      height: 150px;
    `;
const Text = styled.p`
      display: flex;
      flex-wrap: wrap;
      margin: 0.5em 0;
    `;
const Span = styled.span`
      font-weight: 500;
      margin-right: 0.25em
    `;