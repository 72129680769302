import React, { useState, useEffect, useContext } from 'react';
import { EventContext } from '../../context/EventContext';
import styled from 'styled-components';
import EventsNavigation from './EventsNavigation';
import EventsList from './EventsList';
import EventsFilter from './EventsFilter';
import { device } from '../../utilities/breakpoints';
import Loader from './Loader';
import EventPagination from './EventPagination';

const Events = () => {
	const events = useContext(EventContext);

	// Loader to ensure context is set.
	useEffect(() => {
		if (events) {
			setLoading(false);
			handleEvents();
		}
	}, [events]);

	const [loading, setLoading] = useState(true);
	const [filters, setFilters] = useState({'location': null, 'date': null});
	const [currentDate, setCurrentDate] = useState(new Date());
	const [currentMonth, setCurrentMonth] = useState(null);
	const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());
	const [sortedEvents, setSortedEvents] = useState([]);
	const [currentEvents, setCurrentEvents] = useState([]);
	const [filteredEvents, setFilteredEvents] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [maxPage, setMaxPage] = useState(null);

	useEffect(() => {
		// Set session date if set
		const eventDate = JSON.parse(sessionStorage.getItem('eventDate'));
		if (eventDate) {
			setCurrentMonth(new Date(eventDate).getMonth() + 1);
		} else {
			setCurrentMonth(new Date().getMonth() + 1);
		}
	}, []);

	useEffect(() => {
		// Store changed date in session.
		if (currentMonth) {
			const newDate = new Date(currentYear, currentMonth - 1);
			sessionStorage.setItem('eventDate', JSON.stringify(newDate));
		}
	}, [currentMonth, currentYear]);

	const sortByDate = (arr) => {
		const compare = (a, b) => {
			return a.start - b.start;
		};
		const sortedArr = arr.sort(compare);
		return sortedArr;
	}; 

	const handleEvents = () => {
		const sortedEvents = sortByDate(events.events);

		setSortedEvents(sortedEvents);
	};

	const resetEvents = () => {
		setFilters({'location': null, 'date': null});
		setFilteredEvents(null);
		setCurrentMonth(currentDate.getMonth() + 1);
		setCurrentYear(currentDate.getFullYear());
		setCurrentPage(1);
	};

	useEffect(() => {
		setCurrentPage(1);
	}, [currentMonth]);

	const doFilter = () => {
		const res = [];
		const {date, location} = filters;
		const allEvents = [...sortedEvents];

		const searchWords = {
			'stord': ['stord', 'leirvik', 'sagvåg', 'solid'],
			'bomlo': ['bømlo', 'bremnes', 'svortland', 'rubbestadneset', 'langevåg'],
			'fitjar': ['fitjar']
		};
	
		if (date) { 
			// Add check for currentdate aswell.
			if (date.getMonth() + 1  !== currentMonth || date.getFullYear() !== currentYear) {
				setCurrentMonth(date.getMonth() + 1);

				if (date.getFullYear() !== currentYear) {
					setCurrentYear(date.getFullYear());
				}
			} 
	
			const dateFilter = allEvents.filter(event => {
				if (event.start >= date.getTime() / 1000) {
					return event;
				}
			});

			res.push(dateFilter);
		}

		if (location) {
			const locationFilter = allEvents.filter(event => {
				if (location.value === 'alle') {
					return event;
				}

				if (event.app_type === 'eb') {					
					if (event.partner_id === 169 && location.value === 'stord') {
						return event;
					} else if (event.partner_id === 283 && location.value === 'fitjar') {
						return event;
					} else if (event.partner_id === 121 && location.value === 'bomlo') {
						return event;
					}
				} else if (event.app_type === 'fb') {
					let bool = false;
					searchWords[location.value].forEach(item => {
						if (event.app_place_name.toLowerCase().includes(item)) {
							bool = true;
						}
					});
					if (bool) {
						return event;
					}
				} else if (event.app_type === 'af') {
					let bool = false;

					if (event.afa_county === location.value) {
						bool = true;
					}

					if (bool) {
						return event;
					}
				}
			});

			res.push(locationFilter);
		}

		if (location && date && res[0].length && res[1].length) {
			setFilteredEvents(commonElements(res[0], res[1]));
		} else if (location || date) {
			setFilteredEvents(res.flat());
		}
	};

	const commonElements = (dateArr, locationArr) => {
		let inCommon =  [];
	
		if (dateArr.length && locationArr.length) {
			inCommon = dateArr.filter((item) => {
				return locationArr.indexOf(item) > -1;
			});
		} else {
			throw new Error('One or both arrays are undefined');	
		}

		return inCommon;
	};

	useEffect(() => {
		doFilter();
		setCurrentPage(1);
	}, [filters]);

	return (
		loading
			? <Loader />
			: <Wrapper>
				<span id="skip-events"></span>
				<Header>Arrangementskalender!</Header>
				<EventsFilter 
					filters={filters}
					setFilters={setFilters}
					resetEvents={resetEvents}
				/>
				<EventsNavigation
					currentMonth={currentMonth}	
					setCurrentMonth={setCurrentMonth}
					currentYear={currentYear}
					setCurrentYear={setCurrentYear}
					sortedEvents={sortedEvents}
					filteredEvents={filteredEvents}
					setCurrentEvents={setCurrentEvents}
					setMaxPage={setMaxPage}	
					currentDate={currentDate}
				>
					<EventPagination 
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						maxPage={maxPage}
					>
						<EventsList 
							currentEvents={currentEvents}
							currentPage={currentPage}
							resetEvents={resetEvents}
							maxPage={maxPage} 
						/>	
					</EventPagination>	
				</EventsNavigation>
			</Wrapper>
	); 
};

export default Events;

const Wrapper = styled.section`
	padding: 4em;
	margin: 0 20px;
	margin-top: 6em;
	background-color: #fff;
	border-radius: 20px;

	@media ${device.laptop} {
		padding: 1em 2.5em 2em;
	}
	@media ${device.tablet} {
		padding: 2em;
	}
	@media ${device.mobile} {
		margin: 4em 0 0;
		border-radius: 0;
		padding: 1em 0.5em 2em;
	}
`;
const Header = styled.h2`
	text-align: center;

	@media ${device.smMobile} {
		font-size: 16px;
		margin-bottom: 20px;
	}
`;
