import React from 'react';

import demens from '../assets/images/demens.svg';
import horsle from '../assets/images/horsle.svg';
import rorsle from '../assets/images/rorsle.svg';
import psykososialt from '../assets/images/psykososialt.svg';
import syns from '../assets/images/syns.svg';
import utviklings from '../assets/images/utviklings.svg';

const data = {
	nn: {
		pageData: {
			home: {
				title: <h1>Finn dine favorittaktivitetar og bli med på laget!</h1>,
				content: (
					<span>
						<p>
                    Lyst å gjera noko i lag med andre? Begynna på fotball, karate, teater, breakdance, motocross – noko heilt anna?
                    Eller er du kanskje meir sugen på ein konsert, ei utstilling, ein rusletur i godt selskap, å springa eit løp?
							<br /><br />
                    Her finn du ei oppdatert oversikt over både fritidsaktivitetar og komande arrangement i Bømlo, Fitjar og Stord som du kan bli med på!
						</p>
					</span>
				),
			},
			suggest: {
				header: <h1>Har du forslag til andre aktivitetar?</h1>,
				text: <p>
                Er det ein aktivitet du har kjempelyst å starte med og som kanskje mange andre også syns er gøy?
                Fyll ut skjemaet under. Kanskje blir nettopp ditt innspel til verkelegheit!
				</p>
			},
			notfound: {
				header: '404 - Ikkje Funnet'
			}
		},
		components: {
			categories: {
				title: 'Kva vil du vera med på?',
				allButton: <p style={{ fontWeight: 500 }}>Alt!</p>,
				fritidsButton: <p>Med Fritidskort</p>,
				resetTitle: <h3 className="center-text">Ingen aktivitet</h3>,
				resetButton: <p>Nullstill søk</p>,
				accessibilityButton: <p style={{ fontWeight: 400 }}>Tilrettelegging</p>,
				options: {
					counties: [
						{ value: 'alle', label: 'Alle' },
						{ value: 'bømlo', label: 'Bømlo' },
						{ value: 'fitjar', label: 'Fitjar' },
						{ value: 'stord', label: 'Stord' }
					],
					age: [
						{ value: 'alle', label: 'Alle' },
						{ value: 4, label: '4 år' },
						{ value: 5, label: '5 år' },
						{ value: 6, label: '6 år' },
						{ value: 7, label: '7 år' },
						{ value: 8, label: '8 år' },
						{ value: 9, label: '9 år' },
						{ value: 10, label: '10 år' },
						{ value: 11, label: '11 år' },
						{ value: 12, label: '12 år' },
						{ value: 13, label: '13 år' },
						{ value: 14, label: '14 år' },
						{ value: 15, label: '15 år' },
						{ value: 16, label: '16 år' },
						{ value: 17, label: '17 år' },
						{ value: 18, label: '18 år' }
					],
					accessibility: [
						{ value: 11, label: <div><img src={demens} alt='Tilrettelegging ikon demens'/><p>Demens</p></div> },
						{ value: 1, label: <div><img src={horsle} alt='Tilrettelegging ikon høyrslehemming'/><p>Høyrslehemming</p></div> },
						{ value: 10, label: <div><img src={psykososialt} alt='Tilrettelegging ikon psykososialt'/><p>Psykososialt</p></div> },
						{ value: 3, label: <div><img src={rorsle} alt='Tilrettelegging ikon rørslehemming'/><p>Rørslehemming</p></div> },
						{ value: 2, label: <div><img src={syns} alt='Tilrettelegging ikon synshemming'/><p>Synshemming</p></div> },
						{ value: 4, label: <div><img src={utviklings} alt='Tilrettelegging ikon utviklingshemming'/><p>Utviklingshemming</p></div> }
					]
				}
			},
			search: {
				placeholder: 'Søk for aktiviteter'
			},
			languageSelect: {
				textContent: 'For other languages we suggest you use the built in translator functions in Edge or Google Chrome. If you are using other browsers e.g. Firefox, we suggest you enable the Google translate addon.',
			},
			addActivities: {
				teams: {
					header: 'Driv du ein organisert fritidsaktivitet og vil bli del av Fritidskortet?',
					text: <p>Registrer han enkelt via lenka under! Når me har godkjent aktiviteten for deltaking i Fritidskortet, vil aktiviteten og laget, klubben eller organisasjonen din bli synleg her på sida og vera knytt til registrerings- og betalingsfunksjonen.</p>,
					button: <p style={{ fontWeight: 'bold' }}>For lag og organisasjonar</p>
				},
				suggest: {
					header: 'Har du forslag til andre aktivitetar?',
					text: <p>Send oss forslaget ditt! Då får me greie på kva bømlingar, fitjar- og stordabuar i alle aldrar interesserer seg for og ønskjer å driva med på fritida</p>,
					button: <p style={{ fontWeight: 'bold' }}>Forslag til aktivitet</p>
				},
				modal: {
					header: <h2>Registrer ditt lag eller organisasjon</h2>,
					text: <p>For å registrere ditt lag eller organisasjon som aktør kan du følge lenka nedanfor og registrera ein administrator brukar. Bruk helst namnet på ditt lag eller organisasjon som brukarnamn, til dømes «Stord Basket», og send så brukarnamnet på <a href="mailto:fritidskortet@stord.kommune.no">e-post</a> eller på telefon til 92 44 92 11. Send gjerne med kontonummer for innbetaling av treningsavgift, samt om faktura har KID eller ei. Rett gjerne spørsmål til ovannemnte telefon eller e-post.</p>,
					button: <p style={{ fontWeight: 'bold' }}>Registrer ditt lag eller organisasjon</p>
				}
			},
			addActivityForm: {
				firstname: {
					name: 'Kva er fornamnet ditt?*',
					placeholder: 'Fornamnet ditt'
				},
				lastname: {
					name: 'Kva er etternamnet ditt?*',
					placeholder: 'Etternamnet ditt'
				},
				email: {
					name: 'Kva er din epost?*',
					placeholder: 'E-post addressen din'
				},
				suggestion: {
					name: 'Kva er forslaget ditt?*',
					placeholder: 'Forslag til aktivitet'
				},
				description: {
					name: 'Beskriv aktiviteten din:*',
					placeholder: 'Beskriv aktiviteten'
				},
				feedback: {
					success: 'Din epost vart sendt, takk for ditt forslag.',
					failure: 'Vennligst fyll ut alle felt!'
				},
				required: <p>Felt markert med * er påkrevd</p>,
				button: <p style={{ fontWeight: 'bold' }}>Send inn</p>
			},
			otherActivities: {
				header: <h2 style={{ textAlign: 'center' }}>Andre Tilbod</h2>,
				buaStord: {
					header: 'BUA Stord',
					text: <p>BUA Stord er eit tilbod for store og små der ein kan låne sport- og friluftsutstyr heilt gratis.</p>
				},
				buaBomlo: {
					header: 'BUA Bømlo',
					text: <p>BUA Bømlo er eit tilbod for store og små der ein kan låne sport- og friluftsutstyr heilt gratis.</p>
				},
				opplevBomlo: {
					header: 'Opplev Bømlo',
					text: <p>Bla deg gjennom alt som skjer på Bømlo.</p>
				},
				join: {
					header: 'Join Opplevelseskort',
					text: <p>Opplevelseskortet Join er meint for å utjamne sosial ulikskap og motverke sosial ekskludering for barn og unge frå låginntektsfamiliar.</p>
				},
				fritidskassa: {
					header: 'Stord Fritidskassa',
					text: <p>Stord kommune tilbyr frå 01.08.22 fritidskassa som ei ny ordning for å leggja til rette for at barn og unge skal kunna ta del i éin organisert fritidsaktivitet uavhengig av foreldra sin økonomi.</p>
				},
				bomloFrikort: {
					header: 'Bømlo Fritidskort',
					text: <p>I 2024 får alle mellom 11 og 15 år som er busette i Bømlo dekka inntil kr. 2000 til å delta i faste, organiserete aktivietar.</p>
				},
				fitjarFritidskasse: {
					header: 'Fitjar Fritidskasse',
					text: <p>Fitjar kommune si fritidskasse har som mål å inkludere flest mogleg barn og unge i fritidsaktivitetar.</p>
				}

			},
			card: {
				age: 'Alder:',
				place: 'Stad:',
				price: 'Pris:',
				equipment: 'Nødvendig Utstyr:',
				webpage: 'Nettstad:',
				phone: 'Tlf:',
				email: 'E-post:',
				button: <p style={{ fontWeight: 'bold' }}>Bruk Fritidskortet</p>
			},
			footer: {
				text: <p>Aktivitet for alle er eit samarbeidsprosjekt mellom Bømlo, Fitjar og Stord kommune. Om du vil ha meir informasjon kring sida og fritidskortet kan du kontakta prosjektansvarleg i din kommune:</p>
			}
		},
		cookie: {
			text: <p>Denne siden bruker informasjonskapsler</p>,
			button: <p>Forstått</p>
		}
	},
};

export default data;
