export const localDate = (utc, type = null) => {
	const utcDate = new Date(utc);
	const localDate = new Date(Date.UTC(utcDate.getFullYear(), utcDate.getMonth(), utcDate.getDate(), utcDate.getHours(), utcDate.getMinutes(), utcDate.getSeconds()));
	const localeOptions = {day: 'numeric', month: 'long', hour: 'numeric', minute: 'numeric'};
	const locale = 'nn-NO';

	if (type === 'date') {
		delete localeOptions.hour;
		delete localeOptions.minute;
		return localDate.toLocaleDateString(locale, localeOptions);
	} else if (type === 'time') {
		delete localeOptions.day;
		delete localeOptions.month;
		return localDate.toLocaleTimeString(locale, localeOptions);
	} else {
		return localDate.toLocaleTimeString(locale, localeOptions);
	}
};
