import React from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';

const EventsFilter = ({filters, setFilters, resetEvents}) => {

	const handleSelect = (value) => {
		if (value === null) {
			resetEvents();
		}
		setFilters((filters) => ({...filters, 'location': value}));	
	};

	const handleDate = (date) => {
		if (date === null){
			resetEvents();
		}

		setFilters((filters) => ({...filters, 'date': date}));
	};

	const renderFilters = () => {
		const sortingOptionsCounty = [
			{ value: 'alle', label: 'Alle' },
			{ value: 'bomlo', label: 'Bømlo' },
			{ value: 'fitjar', label: 'Fitjar' },
			{ value: 'stord', label: 'Stord' }
		];

		return (
			<div className='event-filters'>
				<section>
					<DatePicker 
						className='event-datepicker'
						selected={filters.date}
						onChange={(date) => handleDate(date)}
						dateFormat='dd-MM-yyyy'
						locale='nn'
						isClearable
						placeholderText='Kalender'
						onFocus={(e) => e.target.readOnly = true} /* hide touch keyboard */
					/>
					<Select
						placeholder={'Kommune'}
						value={filters.location}
						onChange={handleSelect}
						options={sortingOptionsCounty}
						classNamePrefix="event-location"
						className="event-location-filter"
						title='Filtrer etter kommune'
						isSearchable={false}
					/>	
				</section>
			</div>
		);
	};
	return renderFilters(); 
};

export default EventsFilter;