import React, { Component } from 'react';
import styled from 'styled-components';

import { device } from '../../utilities/breakpoints';
import Modal from '../Activities/OrgModal';

export default class AddActivityForm extends Component {
	constructor (props) {
		super(props);

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.postData = this.postData.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleModalClose = this.handleModalClose.bind(this);
		this.firstnameRef = React.createRef();

		this.state = {
			firstname: '',
			lastname: '',
			email: '',
			suggest: '',
			description: '',
			formCompleted: false,
			show: false
		};
	}

	handleModalOpen () {
		this.setState({ show: true });
	}

	handleModalClose () {
		this.setState({ show: false });
	}

	handleEmailChange (e) {
		const target = e.target.value;
		this.setState({ email: target });
	}

	handleInputChange (e) {
		const target = e.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	}

	handleSubmit (e) {
		e.preventDefault();

		const name = this.state.firstname + ' ' + this.state.lastname;

		const { email, suggest, description } = this.state;
		const data = {
			name: name,
			email: email,
			subject: suggest,
			description: description
		};

		this.postData(data);
	}

	async postData (data = {}) {
		// ./mailer.php for prod
		// https://localhost/malier/mailer.php for dev
		// change when building for prod
		const url = './mailer.php';
		try {
			const response = await fetch(url, {
				method: 'POST',
				mode: 'cors',
				body: JSON.stringify(data)
			});
			const json = await response.json();

			if (json.status === 'success') {
				this.setState({ formCompleted: true });
				this.handleModalOpen();
				return json;
			}
			this.handleModalOpen();
		} catch (e) {
			console.log(e);
		}
	}

	render () {
		const { formCompleted } = this.state;
		let feedback;

		const renderFeedback = () => {
			feedback = (
				<Modal className="modal-main" show={this.state.show} handleClose={this.handleModalClose}>
					<Feedback>
						{
							formCompleted
								? addActivityFormFeedback.success
								: addActivityFormFeedback.failure
						}
					</Feedback>
				</Modal>
			);
			return feedback;
		};

		const addActivityFormFirstname = ((this.props.componentText || {}).firstname || {});
		const addActivityFormLastname = ((this.props.componentText || {}).lastname || {});
		const addActivityFormEmail = ((this.props.componentText || {}).email || {});
		const addActivityFormSuggestion = ((this.props.componentText || {}).suggestion || {});
		const addActivityFormDescription = ((this.props.componentText || {}).description || {});
		const addActivityFormFeedback = ((this.props.componentText || {}).feedback || {});

		return (
			<div>
				<Form onSubmit={this.handleSubmit}>
					<InputHeader htmlFor='sug-firstname'>{addActivityFormFirstname.name}</InputHeader>
					<TextInput
						id='sug-firstname'
						aria-required="true"
						required
						className="noinput suggest-form"
						type="text"
						placeholder={addActivityFormFirstname.placeholder}
						name='firstname'
						value={this.state.firstname}
						onChange={this.handleInputChange}
						ref={this.firstnameRef}
						autoComplete='given-name'
					/>
					<InputHeader htmlFor='sug-lastname'>{addActivityFormLastname.name}</InputHeader>
					<TextInput
						id='sug-lastname'
						aria-required="true"
						required
						className="noinput suggest-form"
						type="text"
						placeholder={addActivityFormLastname.placeholder}
						name='lastname'
						value={this.state.lastname}
						onChange={this.handleInputChange}
						autoComplete='family-name'
					/>
					<InputHeader htmlFor='sug-email'>{addActivityFormEmail.name}</InputHeader>
					<TextInput
						id='sug-email'
						aria-required="true"
						required
						className="noinput suggest-form"
						type="email"
						placeholder={addActivityFormEmail.placeholder}
						name='email'
						value={this.state.email}
						onChange={this.handleInputChange}
						autoComplete='email'
					/>
					<InputHeader htmlFor='sug-suggest'>{addActivityFormSuggestion.name}</InputHeader>
					<TextInput
						id='sug-suggest'
						aria-required="true"
						required
						className="noinput suggest-form"
						type="text"
						placeholder={addActivityFormSuggestion.placeholder}
						name='suggest'
						value={this.state.suggest}
						onChange={this.handleInputChange}
					/>
					<InputHeader htmlFor='sug-desc'>{addActivityFormDescription.name}</InputHeader>
					<TextField
						id='sug-desc'
						aria-required="true"
						required
						className="noinput suggest-form"
						type="textfield"
						placeholder={addActivityFormDescription.placeholder}
						name='description'
						value={this.state.description}
						onChange={this.handleInputChange}
					/>
					{this.props.componentText.required}
					<Container>
						<Button
							className="nobutton big-btn"
							type="submit"
							title='Send inn forslag'
						>
							{this.props.componentText.button}
						</Button>
					</Container>
				</Form>
				{renderFeedback()}
			</div>
		);
	}
}

const Form = styled.form`
      display: flex;
      flex-direction: column;
      padding: 0 3em;
      margin-top: 2.5em;
      max-width: 28.5em;
      min-width: 15em;

      @media ${device.mobile}{
          padding: 0;
      }
    `;
const TextInput = styled.input`
      font-size: 1em;
      border-radius: calc(70px / 2);
      height: 3.5em;
      margin-top: 0.5em;
      padding: 0 1em;

      @media ${device.laptop} {
          margin-top: 0;
      }
    `;
const TextField = styled.textarea`
      font-size: 0.9em;
      border-radius: calc(70px / 2);
      height: 20em;
    	padding: 1em;
      margin-top: 0.5em;

      @media ${device.laptop} {
          margin-top: 0;
      }
    `;
const Container = styled.div`
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin-top: 1em;
    `;
const Button = styled.button`
      border-radius: calc(75px / 2);
      padding: 0 1.5em;
      background-color: #AADAA9;
      margin-left: 1.5em;

      &:hover {
          cursor: pointer;
          background-color: #6AA869;
      }
    `;
const Feedback = styled.h2`
      margin: 2em;
      padding: 2em 4em;
    `;
const InputHeader = styled.label`
      font-weight: bold;
      
      @media ${device.laptop} {
          margin: 1em 0 0;
      }
    `;
