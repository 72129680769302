import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import bomlo from '../assets/images/kommune-bomlo.svg';
import fitjar from '../assets/images/kommune-fitjar.svg';
import stord from '../assets/images/kommune-stord.svg';
import { device } from '../utilities/breakpoints';
import { urlDev } from '../utilities/devEnv';

const footer = document.getElementById('footer');

export default class Footer extends React.Component {
	render () {
		const footerText = ((this.props.componentText || {}).footer || {});

		const renderFooter = () => {
			const element = (
				<FooterContainer>
					<Wrapper>
						<TextWrapper>
							<div>
								{footerText.text}
							</div>
							<LinkBox>
								<a className="nolink" href="mailto:fritidskortet@bømlo.kommune.no">fritidskortet@bømlo.kommune.no</a>
								<a className="nolink" href="mailto:fritidskortet@stord.kommune.no">fritidskortet@stord.kommune.no</a>
								<a className="nolink" href="mailto:fritidskortet@fitjar.kommune.no">fritidskortet@fitjar.kommune.no</a>
							</LinkBox>
						</TextWrapper>
						<ImageContainer>
							<a href="https://www.bomlo.kommune.no/" title='Opne i ny fane' target="_blank" rel="noreferrer">
								<Image src={bomlo} alt='Bømlo kommune våpen' />
							</a>
							<a href="https://www.fitjar.kommune.no/" title='Opne i ny fane' target="_blank" rel="noreferrer">
								<Image src={fitjar} alt='Fitjar kommune våpen' />
							</a>
							<a href="https://www.stord.kommune.no" title='Opne i ny fane'target="_blank" rel="noreferrer">
								<Image src={stord} alt='Stord kommune våpen' />
							</a>
						</ImageContainer>
					</Wrapper>
					<BottomRow>
						<a href={`${urlDev}admin`} title='Gå til Administrator omeråde'><p>Adminområde</p></a>
						<a href={`${urlDev}privacy`} title='Gå til personvernserklæring'><p>Personvernserklæring</p></a>
						<a href={'https://uustatus.no/nn/erklaringer/publisert/d879b66e-8561-4116-a4ee-097da3dcbbd5'} target='_blank' rel="noreferrer" title='Åpne tilgjengelighetserklæring i ny fane'><p>Tilgjengelighetserklæring</p></a>
					</BottomRow>
				</FooterContainer>
			);
			return (
				ReactDOM.createPortal(element, footer)
			);
		};
		return (
			renderFooter()
		);
	}
}

const FooterContainer = styled.div`
        background-color: #231F20;
    `;
const Wrapper = styled.div`
        padding: 2em 4em;
        display: flex;
        align-items: center;
        justify-content: space-around;

        @media ${device.mobile} {
            padding: 2em;
            flex-direction: column;
        }
    `;
const TextWrapper = styled.div`
        color: #fff;
        display: flex;
        flex-direction: column;
        width: 600px;
        font-size: 0.9em;

        @media ${device.mobile} {
            width: unset;
        }
    `;
const ImageContainer = styled.div`
        display: flex;

        @media ${device.mobile} {
            margin-top: 2em;
        }
    `;
const LinkBox = styled.div`
        display: flex;
        flex-direction: column;
    `;
const Image = styled.img`
        height: 120px;
        width: 100px;
    `;
const BottomRow = styled.div`
		padding: 0 4em;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-end;

		@media ${device.mobile} {
			flex-flow: column nowrap;
			justify-content: center;
			width: fit-content;
			margin: 0 auto;
		}

		a {
			text-decoration-color: #fff;
		}

		p { 
			font-size: 0.8em;
			color: #fff;
		}
	`;