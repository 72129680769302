import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faSearch } from '@fortawesome/pro-light-svg-icons';
import { Transition } from '@react-spring/core';
import { animated } from '@react-spring/web';

import CategoryButtons from './CategoryButtons';
import ActivityItem from './ActivityItem';
import { device } from '../../utilities/breakpoints';
import Search from './Search';
import Modal from './OrgModal';

export default class Categories extends React.Component {
	constructor (props) {
		super(props);

		this.handleClick = this.handleClick.bind(this);
		this.handleChangeCounty = this.handleChangeCounty.bind(this);
		this.handleChangeAge = this.handleChangeAge.bind(this);
		this.onPageChanged = this.onPageChanged.bind(this);
		this.moveLeft = this.moveLeft.bind(this);
		this.moveRight = this.moveRight.bind(this);
		this.handleReset = this.handleReset.bind(this);
		this.showAll = this.showAll.bind(this);
		this.handleChangeMax = this.handleChangeMax.bind(this);
		this.handleChangeFritidskort = this.handleChangeFritidskort.bind(this);
		this.showSearch = this.showSearch.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.escFunction = this.escFunction.bind(this);
		this.handleModal = this.handleModal.bind(this);
		this.handleA11yButton = this.handleA11yButton.bind(this);
		this.handleChangeFree = this.handleChangeFree.bind(this);

		this.state = {
			activities: [],
			currentCategory: null,
			currentPage: 1,
			perPage: 9,
			showAllEnabled: false,
			selectedOptionCounty: null,
			selectedOptionAge: null,
			selectedMaxAge: false,
			selectedFritidskortet: false,
			selectedFree: false,
			searchIsActive: false,
			searchQuery: '',
			modalIsActive: false,
			selected: []
		};
	}

	componentDidUpdate (props) {
		// if setting allActivites
		if (props.allActivities.length < this.props.allActivities.length) {
			if (this.state.currentCategory === null) {
				this.showAll();
			}
		}
	}
	componentDidMount () {
		const selectedCategory = JSON.parse(sessionStorage.getItem('selectedCategory')) || this.state.currentCategory;
		const currentPage = Number(sessionStorage.getItem('currentPage')) || this.state.currentPage;
		const selectedOptionCounty = JSON.parse(sessionStorage.getItem('selectedOptionCounty')) || this.state.selectedOptionCounty;
		const selectedOptionAge = JSON.parse(sessionStorage.getItem('selectedOptionAge')) || this.state.selectedOptionAge;
		const selectedMaxAge = JSON.parse(sessionStorage.getItem('selectedMaxAge')) || this.state.selectedMaxAge;
		const selectedFritidskortet = JSON.parse(sessionStorage.getItem('selectedFritidskortet')) || this.state.selectedFritidskortet;
		const selectedOptionAccessibility = JSON.parse(sessionStorage.getItem('selectedOptionAccessibility')) || this.state.selectedOptionAccessibility;
		const selectedFree = JSON.parse(sessionStorage.getItem('selectedFree')) || this.state.selectedFree;
		const activities = JSON.parse(sessionStorage.getItem('activities')) || this.state.activities;
		this.setState({
			currentCategory: selectedCategory,
			currentPage: currentPage,
			selectedOptionCounty: selectedOptionCounty,
			selectedOptionAge: selectedOptionAge,
			selectedMaxAge: selectedMaxAge,
			selectedFritidskortet: selectedFritidskortet,
			selectedOptionAccessibility: selectedOptionAccessibility,
			selectedFree: selectedFree,
			activities: activities
		});
	}

	handleClick (e) {
		const target = e.currentTarget.value;
		const catId = Number(target);
		const {currentCategory} = this.state;
		const toggleCat = currentCategory === catId ? null : catId;
		const showAll = toggleCat === null;

		// Toggle activeCat and show all
		if (!showAll) {
			this.setState({
				currentCategory: toggleCat,
				showAllEnabled: showAll
			});
			// filter all activities and all options
			this.filter({ type: 'category', value: toggleCat });
		} else {
			this.showAll();
		}

		// save catagory in session
		sessionStorage.setItem('selectedCategory', JSON.stringify(toggleCat));
	}

	showAll () {
		this.handleReset();
		this.setState({ showAllEnabled: true });
	}

	filter (options = { type: null }) {
		const {
			currentCategory,
			selectedOptionCounty,
			selectedOptionAge,
			selectedFritidskortet,
			selectedMaxAge,
			selectedFree,
			selected
		} = this.state;

		let activities = this.props.allActivities;

		if (options.type === 'category' || currentCategory) {
			const cat = options.type === 'category' ? options.value : currentCategory;
			activities = activities.filter((a) => a.serviceType.category.id === cat);
		}

		if (options.type === 'county' || selectedOptionCounty) {
			const county = options.type === 'county' ? options.value : selectedOptionCounty.value;
			activities = activities.filter((el) => county === 'alle' ? true : el.geo.name.toLowerCase() === county.toLowerCase());
		}

		if (options.type === 'age' || selectedOptionAge) {
			const age = options.type === 'age' ? options.value : selectedOptionAge.value;

			const filterByAge = (el) => {
				const min = el.ageFrom;
				const max = el.ageTo;
				let score = 0;

				if (min !== null) {
					if (min <= age) {
						score += 0.5;
					}
				} else {
					score += 0.5;
				}
				if (max !== null) {
					if (max >= age) {
						score += 0.5;
					}
				} else {
					score += 0.5;
				}
				return score > 0.5;
			};

			if (age !== 'alle') {
				activities = activities.filter(filterByAge);
			}
		}

		if (options.type === 'accessibility' || selected) {
			if (selected) {
				selected.forEach(element => {
					activities = activities.filter(activity => {
						const targetGroups = activity.targetGroups;
						let bool = false;
						for (let i = 0; i < targetGroups.length; i++) {
							if (targetGroups[i].id === element) {
								bool = true;
							}
						}
						return bool;
					});
				});
			}
		}

		if (options.type === 'maxAge' || selectedMaxAge) {
			const age = 60;

			const filterByMaxAge = (el) => {
				const min = el.ageFrom;
				let score = 0;

				if (min !== null) {
					if (age <= min) {
						score += 0.5;
					}
				}

				return score > 0;
			};
			if (selectedMaxAge) {
				activities = activities.filter(filterByMaxAge);
			}
		}

		if (options.type === 'fritidskort' || selectedFritidskortet) {
			if (selectedFritidskortet) {
				activities = activities.filter(activity => {
					return activity.includedInGrantSchemes.length;
				});
			}
		}

		if (options.type === 'free' || selectedFree) {
			if (selectedFree) {
				activities = activities.filter(activity => {
					return activity.totalPrice === 0;
					// Show activites only if price specifically set to 0;
				});
			}
		}

		this.setState({
			activities: activities,
			currentPage: 1
		}, () => {
			sessionStorage.setItem('currentPage', 1 + '');
			sessionStorage.setItem('activities', JSON.stringify(activities));
		});
	}

	onPageChanged (e) {
		this.setState({ currentPage: Number(e.target.id) }, () => {
			sessionStorage.setItem('currentPage', this.state.currentPage + '');
		});
	}

	moveLeft () {
		const currentPage = this.state.currentPage;
		this.setState({ currentPage: currentPage - 1 }, () => {
			sessionStorage.setItem('currentPage', this.state.currentPage + '');
		});
	}

	moveRight () {
		const currentPage = this.state.currentPage;
		this.setState({ currentPage: currentPage + 1 }, () => {
			sessionStorage.setItem('currentPage', this.state.currentPage + '');
		});
	}

	handleChangeCounty (selectedOptionCounty) {
		this.setState({
			selectedOptionCounty: selectedOptionCounty
		}, () => {
			sessionStorage.setItem('selectedOptionCounty', JSON.stringify(selectedOptionCounty));
		});
		this.filter({ type: 'county', value: selectedOptionCounty.value });
	}

	handleChangeAge (selectedOptionAge) {
		this.setState({
			selectedOptionAge: selectedOptionAge
		}, () => {
			sessionStorage.setItem('selectedOptionAge', JSON.stringify(selectedOptionAge));
		});
		this.filter({ type: 'age', value: selectedOptionAge.value });
	}

	handleChangeMax () {
		const max = this.state.selectedMaxAge;
		this.setState({ selectedMaxAge: !max, selectedOptionAge: null }, () => {
			sessionStorage.setItem('selectedMaxAge', JSON.stringify(!max));
			this.filter({ type: 'maxAge', value: this.state.selectedMaxAge });
		});
	}

	handleChangeFritidskort () {
		const fritidskortet = this.state.selectedFritidskortet;
		this.setState({ selectedFritidskortet: !fritidskortet }, () => {
			sessionStorage.setItem('selectedFritidsKortet', JSON.stringify(!fritidskortet));
			this.filter({ type: 'fritidskortet', value: this.state.selectedFritidskortet });
		});
	}

	handleChangeFree () {
		const free = this.state.selectedFree;
		this.setState({ selectedFree: !free }, () => {
			sessionStorage.setItem('selectedFree', JSON.stringify(!free));
			this.filter({ type: 'free', value: this.state.selectedFree});
		});
	}

	handleReset () {
		this.setState({
			selectedOptionAge: null,
			selectedOptionCounty: null,
			selectedOptionAccessibility: null,
			currentCategory: null,
			selectedMaxAge: false,
			selectedFritidskortet: false,
			selectedFree: false,
		}, () => {
			this.filter();
		});
	}

	showSearch () {
		const isActive = this.state.searchIsActive;
		this.setState({
			searchIsActive: !isActive
		});
		this.filter();
	}

	handleSearch (e) {
		e.preventDefault();

		let activities = this.props.allActivities;
		const query = e.target.value;

		this.setState({ searchQuery: query });

		if (query.length) {
			activities = activities.filter(activity => {
				const activityName = activity.name.toLowerCase();
				const activityIngress = (activity.informations || [])[0].teaser.toLowerCase();
				const activityProvider = (activity.serviceProvider || {}).name.toLowerCase();

				switch (true) {
					case (activityName ? activityName.includes(query.toLowerCase()) : false) :
						return true;
					case (activityIngress ? activityIngress.includes(query.toLowerCase()) : false):
						return true;
					case (activityProvider ? activityProvider.includes(query.toLowerCase()) : false):
						return true;
					default:
						return false;
				}
			});
		}

		if (activities.length) {
			this.setState({ activities: activities });
		}
	}

	escFunction (e) {
		if (e.key === 'Escape') {
			this.setState({ searchIsActive: false });
		}
	}

	handleModal () {
		const isActive = this.state.modalIsActive;
		this.setState({ modalIsActive: !isActive });
	}

	handleA11yButton (position) {
		const selected = this.state.selected;
		const find = selected.indexOf(position);
		if (find > -1) {
			selected.splice(find, 1);
		} else {
			selected.push(position);
		}
		this.setState({ selected }, () => {
			this.filter({ type: 'accessiblity', value: this.state.selected });
		});
	}

	render () {
		const {
			activities,
			currentPage,
			perPage,
			currentCategory,
			searchIsActive
		} = this.state;

		// Logic for displaying activities unsorted
		const indexOfLastActivity = currentPage * perPage;
		const indexOfFirstActivity = indexOfLastActivity - perPage;

		const currentActivities = activities.slice(indexOfFirstActivity, indexOfLastActivity);

		const renderActivityItems = currentActivities.map((activity) => {
			return <ActivityItem key={activity.id} id={activity.id} name={activity.name} activity={activity} language={this.props.language} />;
		});

		// Logic for displaying page numbers
		const pageNumbers = [];
		if (activities.length > 0) {
			for (let i = 1; i <= Math.ceil((activities.length + 0.1) / perPage); i++) {
				pageNumbers.push(i);
			}
		}

		const setFirstPage = () => {
			this.setState({ currentPage: 1 }, () => {
				sessionStorage.setItem('currentPage', this.state.currentPage + '');
			});
		};
		const setLastPage = () => {
			this.setState({ currentPage: pageNumbers.length }, () => {
				sessionStorage.setItem('currentPage', this.state.currentPage + '');
			});
		};

		const renderPageNumbers = () => {
			return (
				<>
					<PaginationButton
						className={`nobutton prev 
              				${currentPage !== 1 ? '' : 'disabled'} 
              				${pageNumbers.length === 0 ? 'hidden' : ''}`}
						onClick={this.moveLeft}
						disabled={currentPage === 1}
						title='Tilbake'
					>
						<FontAwesomeIcon icon={faChevronLeft} />
					</PaginationButton>
					<div className='pagination-number-wrapper'>
						{ currentPage >= 5
							? <>
								<button
									onClick={setFirstPage}
									className='nobutton pagination-first'
									style={{ alignItems: 'center' }}
								>
									{pageNumbers[0]}
								</button>
							</>
							: ''
						}
						{
							pageNumbers.map(number => {
								return (
									<button
										className={`nobutton pagination-item
										${currentPage === number ? 'active' : ''}
										${currentPage - 1 === number ||
										currentPage + 1 === number ||
										currentPage === number || (
										currentPage === 1 && currentPage + 2 === number
									) || (
										currentPage === pageNumbers.length && currentPage - 2 === number
									)
										? ''
										: 'hidden'
									}`}
										key={number}
										id={number}
										onClick={this.onPageChanged}
										title={`Gå til side ${number}`}
									>
										{number}
									</button>
								);
							})
						}
						{ currentPage >= 5 &&
						currentPage !== pageNumbers.length
							? <>
								<button
									onClick={setLastPage}
									className='nobutton pagination-last'
									style={{ alignItems: 'center', justifyContent: 'right' }}
								>
									{pageNumbers.length}
								</button>
							</>
							: ''
						}
					</div>
					<PaginationButton
						className={`nobutton next 
                                ${currentPage === pageNumbers.length ? 'disabled' : ''}
                                ${pageNumbers.length === 0 ? 'hidden' : ''}
                            `}
						onClick={this.moveRight}
						disabled={this.state.currentPage === pageNumbers.length}
						title='Neste'
					>
						<FontAwesomeIcon icon={faChevronRight} />
					</PaginationButton>
				</>
			);
		};

		const categoryText = ((this.props.componentText || {}).categories || {});
		const sortingOptionsCounty = ((categoryText || {}).options || {}).counties;
		const sortingOptionsAge = ((categoryText || {}).options || {}).age;
		const sortingOptionsAccessibility = ((categoryText || {}).options || {}).accessibility;

		const renderActivities = () => {
			if (!activities.length) {
				return (
					<Container>
						{currentCategory
							? (
								<>
									{categoryText.resetTitle}
									<button
										className="reset-btn nobutton"
										onClick={this.handleReset}
										title='Tilbakestill filtre'
									>
										{categoryText.resetButton}
									</button>
								</>
							)
							: '' }
					</Container>
				);
			} else {
				return <Activities> {renderActivityItems} </Activities>;
			}
		};

		const renderModal = () => {
			if (this.state.modalIsActive) {
				return (
					<Modal className="a11y-modal-main" show={this.state.modalIsActive} handleClose={this.handleModal}>
						<div style={{ padding: '1em' }}>
							<ModalHeader>{categoryText.accessibilityButton}</ModalHeader>
							<ModalButtonWrapper>
								{sortingOptionsAccessibility.map((item, index) => {
									return (
										<ModalButton
											key={index}
											type='button'
											className={`nobutton modal-checkbox ${
												this.state.selected.includes(item.value)
													? 'checkbox-isactive'
													: ''
											}`}
											onClick={() => this.handleA11yButton(item.value)}
											selected={this.state.selected.includes(item.value)}
											value={item.value}
										>
											{item.label}
										</ModalButton>
									);
								})}
							</ModalButtonWrapper>
						</div>
					</Modal>
				);
			}
		};

		return (
			<Wrapper>
				<span id="skip-categories"></span>
				<Header>{categoryText.title}</Header>
				<ButtonWrapper>
					<CategoryButtons
						categories={this.props.categories}
						current={currentCategory}
						handleClick={this.handleClick}
						allEnabled={this.state.showAllEnabled}
						allJsx={categoryText.allButton}
						showAll={this.showAll}
					/>
				</ButtonWrapper>
				<SortingWrapper>
					<Transition
						items={searchIsActive}
						from={{ width: '50%', opacity: 0 }}
						enter={{ width: '100%', opacity: 1 }}
						reverse={searchIsActive}
					>
						{(styles, item) =>
							item
								? <animated.div style={{ ...styles, height: '60px' }}>
									<Search
										activities={activities}
										showSearch={this.showSearch}
										searchIsActive={searchIsActive}
										value={this.state.searchQuery}
										handleSearch={this.handleSearch}
										componentText={this.props.componentText}
										escFunction={this.escFunction}
									/>
								</animated.div>
								: <animated.div style={{ ...styles }}>
									<div id="filter-buttons">
										<Select
											placeholder={'Kommune'}
											value={this.state.selectedOptionCounty}
											onChange={this.handleChangeCounty}
											options={sortingOptionsCounty}
											classNamePrefix="county"
											className="county"
											title='Filtrer etter kommune'
										/>
										<Select
											placeholder={'Alder'}
											value={this.state.selectedOptionAge}
											onChange={this.handleChangeAge}
											options={sortingOptionsAge}
											classNamePrefix="age"
											className="age"
											isDisabled={!!this.state.selectedMaxAge}
											title='Filtrer etter alder'
										/>
										<InfoButton
											type='button'
											className={`nobutton ${
												this.state.selected.length
													? 'a11y-button-active'
													: ''
											}`}
											onClick={this.handleModal}
											title='Filtrer etter tilgjengelighet'
										>
											{categoryText.accessibilityButton}
										</InfoButton>
										{renderModal()}
										<div>
											<button
												type="button"
												className={`nobutton checkbox ${
													this.state.selectedMaxAge ? 'checkbox-is-active' : ''
												}`}
												value={this.state.selectedMaxAge}
												onClick={this.handleChangeMax}
												title='Filtrer etter alder 65 og oppover'
											>
												<p>65+</p>
											</button>
											<button
												type="button"
												className={`nobutton checkbox ${
													this.state.selectedFree ? 'checkbox-is-active' : ''
												}`}
												value={this.state.selectedFree}
												onClick={this.handleChangeFree}
												title='Filtrer etter gratis aktiviteter'
											>
												<p>Gratis</p>
											</button>
										</div>
										<button
											type="button"
											className={'nobutton search-button'}
											value={this.state.searchIsActive}
											onClick={this.showSearch}
											title='Søk etter aktiviteter'
										>
											<FontAwesomeIcon icon={faSearch} />
										</button>
									</div>
								</animated.div>
						}
					</Transition>
				</SortingWrapper>
				<ActivityWrapper>
					{renderActivities()}
					<Pagination className='nolist'>
						{renderPageNumbers()}
					</Pagination>
				</ActivityWrapper>
			</Wrapper>
		);
	}
}

const Wrapper = styled.section`
    padding: 1em 4em 4em;
    margin: 5em 1em 3em;
    background: #fff;
    border-radius: 20px;

    @media ${device.laptop}{
      padding: 1em 2.5em 2em;
    }
    @media ${device.mobile}{
      margin: 4em 0 0;
      border-radius: 0;
      padding: 1em .5em 2em;
    }
  `;
const Header = styled.h2`
    text-align: center;
    margin: 0.5em 0 1em;

    @media ${device.mobile} {
        margin: 1em 0 1.5em;
    }
  `;
const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @media ${device.mobile} {
        // display: grid;
        // grid-template-columns: repeat(2, 1fr);
    }
  `;
const SortingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 4em;
    align-items: center;
    justify-content: space-around;
  `;
const Activities = styled.div`
    margin: 2em 0 0;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    word-break: break-word;
    height: 750px;

    @media  ${device.mobile}{
      display: flex;
      flex-direction: column;
      height: auto;
      padding: 0 3em;
    }

	@media ${device.smMobile}{
		padding: 0;
	}
  `;
const Pagination = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 2em;
  `;
const PaginationButton = styled.button`
  `;
const Container = styled.div`
      display: flex;
      flex-direction: column;
    `;
const ActivityWrapper = styled.div`
      margin: 4em 0 0;
    `;

const InfoButton = styled.button`
        border: 1px solid #000;
        border-radius: 20px;
        height: 44px;
        padding: 0 1em;
        margin-left: 10px;
        cursor: pointer;

        &:hover {
            color:#159B41;
        }

        @media ${device.mobile} {
            height: 38px;
        }
    `;
const ModalHeader = styled.h2`
		@media ${device.mobile} {
			font-size: 1.1125rem;
		}
    `;
const ModalButton = styled.button`
        display: flex;
        align-items: center;
        cursor: pointer;
		height: 68px;

		@media ${device.mobile} {
			height: 30px;
			margin: 0.2rem 0;
		}

        img {
            margin-right: 1em;
            height: 50px;

			@media ${device.mobile} {
				height: 20px;
				margin-right: 0.25em;
			}
        }
		p {
			font-weight: 400;
			margin: 0;

			@media ${device.mobile} {
				font-weight: 300;
				font-size: 0.9rem;
			}
		}

		div {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
		}
    `;
const ModalButtonWrapper = styled.div`
        display: flex;
        flex-wrap: wrap;
    `;
