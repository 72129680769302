import React from 'react';
import PaginationButton from './PaginationButton';

const PageNumbers = ({ setCurrentPage, currentPage, maxPage }) => {

	const handleClasses = (pageIndex) => {
		let classes = '';
		const firstPage = 1;
		const lastPage = maxPage;

		currentPage === pageIndex 
			? classes += 'pagination-page active'
			: classes += 'pagination-page';

		if (pageIndex > currentPage + 2 || pageIndex < currentPage - 2) {
			classes += ' pagination-hidden';
		}

		if (pageIndex === firstPage) {
			classes = classes.replace('pagination-hidden', '');

			if (currentPage > firstPage + 2) {
				classes += ' pagination-first-page';
			} 
		}

		if (pageIndex === lastPage) {
			classes = classes.replace('pagination-hidden', '');

			if (lastPage > currentPage + 2) {
				classes += ' pagination-last-page';
			}
		}

		return classes;
	};


	const renderPageNumbers = () => {
		const firstPage = 1;
		const lastPage = maxPage;
		const pageNumbers = [];

		for(let page = firstPage; page <= lastPage; page++) {
			pageNumbers.push(
				<PaginationButton 
					key={`event-pagination-${page}`} 
					setCurrentPage={setCurrentPage} 
					page={page} 
					classes={handleClasses(page)}
				/>
			);
		}

		return pageNumbers; 
	};

	return renderPageNumbers();
};

export default PageNumbers;