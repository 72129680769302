import React, {createContext, useState, useEffect} from 'react';
import { dev } from '../utilities/devEnv';

const EventContext = createContext();

const EventContextProvider = ({ children }) => {
	const [data, setData] = useState({});
	const [events, setEvents] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			const url = `${dev}/events/action/get_events`;
			let temp = {};

			try {
				await fetch(url, {
					method: 'GET',
					headers: {
						'x-api-key': '071bb2abbfb9d54bb7f71da320183e',
						Accept: 'application/json'
					}
				})
					.then(res => res.json())
					.then((data) => {
						if (data.code === 200) {
							temp = data;
						} else {
							throw new Error('Ingen arrangement funnet');
						}
					});
			} catch (e) {
				console.log(e.name, ':', e.message);
			}

			if (!Object.keys(temp).length) {
				return;
			} 

			// Add different date properties to events
			const modifiedEvents = temp.events.map(event => {
				let newDate = new Date(event.start * 1000);
				let endDate = new Date(event.end * 1000);

				if (event.app_type === 'fb') {
					newDate = new Date(event.app_start_time * 1000);
					if (event.app_end_time) {
						endDate = new Date(event.app_end_time * 1000);
					} 

					event.start = Number(event.app_start_time);
				}

				if (event.app_type === 'eb') {
					newDate = new Date(event.begin);
					if (event.end) {
						endDate = new Date(event.end);
					}

					// set correct timestamp
					event.start = newDate.getTime() / 1000;
				}

				if (event.app_type === 'af') {
					event.start = Number(event.start);
				}

				event.year = newDate.getFullYear();
				event.month = newDate.getMonth() + 1;
				event.date = newDate;
				event.endDate = endDate;
				event.isMultipleDays = false;

				return event;
			});

			temp.events = modifiedEvents;

			setEvents(temp);	
		};

		fetchData();
	}, []);

	return (
		<EventContext.Provider value={events}>
			{children}
		</EventContext.Provider>
	);

};

export {EventContext, EventContextProvider};