import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

const FacebookLogin = ({ onLogin, isFbLogin }) => {
	  const handleFacebookLogin = () => {
		if (isFbLogin) {
			// User is allready logged in, fetch access token
			window.FB.api('/me', { fields: 'id, email' }, function (response) {
				if (response.id) {
					onLogin(window.FB.getAccessToken());
				}
			});
		} else {
			window.FB.login(
				function (response) {
					if (response.authResponse) {
						onLogin(response.authResponse.accessToken);
					} else {
						console.log('User cancelled login or did not fully authorize.');
					}
				},
				{ scope: 'public_profile,email' }
			);
		}
	  };


	return (
		<div className='facebook-login'>
			<h1>Arrangør login</h1>
			<p>Her må du logge inn med Facebook brukeren som laget arrangementet på Facebook.</p>
			<button 
				type='button'
				className='btn-facebook'
				id='organizer-login'
				onClick={() => {handleFacebookLogin();}}
				title='Logg inn med facebook'
			>
				<FontAwesomeIcon icon={faFacebook} /> Logg inn med Facebook
			</button>
		</div>
	);
};

export default FacebookLogin;