import React, { useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { device } from '../../utilities/breakpoints';

const Modal = ({ handleClose, show, children, className }) => {
	const showHideClassName = show ? 'modal display-block' : 'modal display-none';

	const useOutside = ref => {
		useEffect(() => {
			function handleClickOutside (event) {
				if (ref.current && !ref.current.contains(event.target)) {
					handleClose();
				}
			}

			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}, [ref]);
	};

	const outerRef = useRef(null);
	useOutside(outerRef);

	const escFunction = useCallback((e) => {
		if (e.key === 'Escape') {
			handleClose();
		}
	}, []);

	useEffect(() => {
		document.addEventListener('keydown', escFunction);

		return () => {
			document.removeEventListener('keydown', escFunction);
		};
	}, []);

	return (
		<Outer className={showHideClassName}>
			<Inner ref={outerRef} className={className}>
				<CloseButton
					type="button"
					className="nobutton close-btn"
					onClick={handleClose}
					title='Lukk modal'
				>
					<FontAwesomeIcon icon={faTimes}/>
				</CloseButton>
				{children}
			</Inner>
		</Outer>
	);
};
export default Modal;

const Outer = styled.div`
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
        z-index: 1;
		background: rgba(0, 0, 0, 0.6);
	`;
const Inner = styled.section`
		position: fixed;
		background-color: #f8f8ef;
		width: 80%;
		height: auto;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
        border-radius: 20px;

		@media ${device.mobile} {
			width: 100%;
			border-radius: 0;
			height: 100%;
		}

	`;
const CloseButton = styled.button`
        float: right;
        margin-top: 20px;
        margin-right: 20px;
    `;
