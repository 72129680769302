import React from 'react';
import { dev } from '../../utilities/devEnv';

const AdminAreaApplications = ({ applications, setFeedback, setApplications }) => {

	const handleApplication = (string, event_id) => {
		const url = `${dev}/events/action/handle_application`;

		const data = new FormData();
		data.append('status', string);
		data.append('event_id', event_id);

		fetch(url, {
			method: 'POST',
			body: data
		})
			.then(res => res.json())
			.then((data) => {
				if (data.code === 200) {
					setFeedback(data.feedback);

					const newArr = applications.filter(appl => {
						if (appl.event_id !== data.id) {
							return appl;
						}
					});

					setApplications(newArr);
				}
			});
	};

	const renderApplications = () => {
		const appl = applications.map(event => {

			// Handle application date (timestamp to datetime)
			const date = new Date(event.app_start_time * 1000);

			// Handle application status
			let status = '';
			switch(event.app_status) {
				case 'approved':
					status = 'Godkjent';
					break;
				case 'rejected':
					status = 'Avvist';
					break;
				default:
					status = 'Venter godkjenning';
					break;
			}

			return (
				<li key={event.event_id}>
					<div>
						<h3>{event.app_name}</h3>
						<span>
							<p>Beskrivelse: </p>
							<p>{event.app_description}</p>
						</span>
						<span>
							<p>Sted: </p>
							<p>{event.app_place_name}</p>
						</span>
						<span>
							<p>Tid: </p>
							<p>{date.toLocaleString()}</p>
						</span>
						<span>
							<p>Status:</p>
							<p>{status}</p>
						</span>
					</div>
					<div>
						<button 
							className='admin-approve-btn'
							type='button'
							onClick={() => {handleApplication('approved', event.event_id);}}
							title='Godkjenn arrangement'
						>
							<p>Godkjenn</p>
						</button>
						<button 
							className='admin-reject-btn'
							type='button'
							onClick={() => {handleApplication('rejected', event.event_id);}}
							title='Avvis arrangement'
						>
							<p>Avvis</p>
						</button>
					</div>
				</li>
			);
		});

		return appl;
	};

	return (
		<>
			<h3>Facebook arrangement forespørslar</h3>
			<ul>
				{
					renderApplications()
				}
			</ul>
		</>
	);
};

export default AdminAreaApplications;