import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import { device } from '../../utilities/breakpoints';

const AdminSearch = ({ setFilters, filters }) => {
	const handleValue = (e) => {
		const target = e.target;
		setFilters({...filters, ['searchInput']: target.value});
	};
	return (
		<div className='admin-search'>
			<label htmlFor="admin-search">Søk etter arrangement</label>
			<SearchInputWrapper>
				<FontAwesomeIcon icon={faSearch} />
				<input 
					type='search' 
					id='admin-search' 
					name='admin-search' 
					onChange={(e) => {handleValue(e);}}
					placeholder='Arrangement namn'
				/>
			</SearchInputWrapper>
		</div>
	);
};

export default AdminSearch;

const SearchInputWrapper = styled.div`
	input {
		padding: 0 0 0 2.5rem;
		width: 531px;
		height: 61px;
		border-radius: 31px;
		outline: none;
		border: 1px solid #707070;
		font-size: 1.2rem;

		@media ${device.mobile} {
			width: 100%;
		}
	}

	svg {
		position: absolute;
		left: 10px;
		color: #707070;
		font-size: 22px;
		bottom: 25%;
	}
`;