import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { device } from '../../utilities/breakpoints';
import { localDate } from '../../utilities/localDate';
import { urlDev } from '../../utilities/devEnv';

const EventItem = ({ event }) => {
	const handleDate = () => {
		let parts = [localDate(new Date(event.start * 1000))];

		// Handle date for ebillett event
		if (event.app_type === 'eb') {
			const date = new Date(event.start * 1000);
			const dato = date.toLocaleDateString('nn-NO', {day: 'numeric', month: 'long'});
			const time = date.toLocaleTimeString('nn-NO', {hour: 'numeric', minute: 'numeric' });
			const eb_date = dato + ' kl. ' + time;
			parts = [eb_date];
		}
		if (event.isMultipleDays) {
			const enDate = localDate(event.endDate);
			parts.push(enDate);
		}
		// handle date for facebook event
		if (event.app_start_time) {
			const date = new Date(event.app_start_time * 1000);
			parts = [localDate(date)];
		}
		// handle location for facebook event
		if (event.app_place_name) {
			parts.push(event.app_place_name);
		}
		// Handle location for ebillett event
		if (event.partner_id) {
			let location = '';
			let place = '';

			if (event.renter.id === 1 || event.renter.id === null) { 
				switch(event.partner_id) {
					case 169:
						location = 'Stord kulturhus';
						break;
					case 283:
						location = 'Fitjar kulturhus';
						break;
					case 121:
						location = 'Bømlo kulturhus';
						break;
				}
			}

			if (event.location.name) {
				place = event.location.name;
			}

			let eventLocation = '';
			if (location) {
				eventLocation = location + ', ' + place;
			} else {
				eventLocation = place;
			}

			parts.push(eventLocation);
		}
		if (event.geo?.name) {
			parts.push(event.geo?.name);
		}
		return parts.join(' - ');
	};

	const handleTitle = (event) => {
		if (event.app_type === 'fb') {
			return event.app_name;
		} else if (event.app_type === 'eb') {
			return event.title;
		} else {
			return event.titles[0].value;
		}
	};

	const handleImage = () => {
		if (event.app_type === 'eb') {
			let image_url = '';
			const image = event?.production?.assets;
			if (image) {
				image.forEach(item => {
					if (item.type === 'poster') {
						image_url = item.url;
					}
				});
			}
			return image_url;
		} else {
			return event.pictureLink;
		}
	};

	const renderEventItem = () => {
		if (event.app_type === 'fb') {
			return (
				<EventWrapper id={event.event_id}>
					<Img />
					<TextWrapper>
						<DateWrapper>{handleDate()}</DateWrapper>
						<a 
							href={`https://facebook.com/events/${event.event_id}`}
							target={'_blank'} 
							rel='noreferrer'
							title='Gå til facebook arrangement'
						>
							<EventHeader>{handleTitle(event)}</EventHeader>
						</a>
					</TextWrapper>
					<MoreBtn 
						href={`https://facebook.com/events/${event.event_id}`}
						target={'_blank'}
						rel='noreferrer'
						title='Gå til facebook arrangement'
					>
						Les meir
					</MoreBtn>
				</EventWrapper>
			);
		} else if (event.app_type === 'eb') {
			return (
				<EventWrapper id={event.id}>
					<Img img={handleImage()} />
					<TextWrapper>
						<DateWrapper>{handleDate()}</DateWrapper>
						<Link to={`${urlDev}event/${event.id}/${event.app_type}`} className="nolink event-item" title="Gå til arrangement">
							<EventHeader>{handleTitle(event)}</EventHeader>
						</Link>
					</TextWrapper>
					<MoreBtn
						href={`${urlDev}event/${event.id}/${event.app_type}`} title="Gå til arragnement">
							Les meir
					</MoreBtn>
				</EventWrapper>
			);
		} else {
			return (
				<EventWrapper id={event.id}>
					<Img img={handleImage()} />
					<TextWrapper>
						<DateWrapper>{handleDate()}</DateWrapper>
						<Link to={`${urlDev}event/${event.id}/${event.app_type}`} className="nolink event-item" title='Gå til arrangement'>
							<EventHeader>{handleTitle(event)}</EventHeader>
						</Link>
					</TextWrapper>
					<MoreBtn href={`${urlDev}event/${event.id}/${event.app_type}`} title='Gå til arrangement'>
						Les meir
					</MoreBtn>
				</EventWrapper>
			);
		}
	};

	return (
		renderEventItem()
	);
};

export default EventItem;

const EventWrapper = styled.div`
        display: grid;
        grid-template-columns 1fr 4fr 1fr;
        flex-flow: row nowrap;
        align-items: center;
        margin-bottom: 15px;
        border-radius: 0.75em;
        background-color: #FBFBF6;
        border: 1px solid #F5F4EA;
        padding: 1em;

        @media ${device.tablet} {
            padding: 0.5em;
        }

        @media ${device.mobile} {
            padding: 0.25em;
            display: block;
        }
    `;
const Img = styled.div`
        height: 100px;
        width: 100px;
        background: url(${props => props.img}) center center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 100%;
        justify-self:center;

        @media ${device.tablet} {
            height: 90px;
            width: 90px;
        }
        @media ${device.mobile} {
            display: none;
        }
    `;
const TextWrapper = styled.article`
        max-width: 550px;

        @media ${device.tablet} {
            padding: 0;
            align-self: flex-start;
        }
        @media ${device.mobile}{
            max-width: unset;
            display: flex;
            flex-flow: column nowrap;
            width: 95%;
            padding: 0.25em;
        }
    `;
const DateWrapper = styled.p`
        font-weight: 500;
        font-size: .8em;
        margin: 0;
    `;
const EventHeader = styled.h2`
        font-size: 1.1em;
        text-decoration: underline;
        margin: 0;
        display: inline-block;

        @media ${device.laptop}{
            font-size: 1.1em;
        }

        @media ${device.mobile}{
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
			font-size: 1rem;
        }

        &:hover {
            color: #159B41;
        }
    `;
const MoreBtn = styled.a`
        font-size: .8em;
        color: inherit;
        text-decoration: none;

        &:hover {
            color: #159B41;
        }
    `;