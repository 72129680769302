import React from 'react';
import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { dev, urlDev } from '../utilities/devEnv';

const Login = ({ setLoading, setAuthenticated, isAuthenticated }) => {
	const [feedback, setFeedback] = useState('');

	if (isAuthenticated) {
		return <Redirect to={`${urlDev}admin`} />;
	}

	const handleForm = (e) => {
		e.preventDefault();

		const form = document.getElementById('loginForm');
		const data = new FormData(form);
		const url = `${dev}/auth/action/login`;

		setLoading(true);
		fetch(url, {
			method: 'POST',
			body: data 
		})
			.then((res) => {
				if (res.status === 200) { 
					return res.json();
				} else {
					alert(`Server error - ${res.status}`);
					// Give Feed Back here;
				}
			})
			.then((data) => {
				if (data) {
					if (data.authenticated) {
						setAuthenticated(data.authenticated);
					} else {
						setFeedback(data.feedback);
					}
					setLoading(false);
				}
			});

		if (isAuthenticated) {
			return <Redirect to={`${urlDev}admin`} />;
		} else {
			return;
		}

	};

	const renderLogin = () => {
		return (
			<section className='page login-page'>
				<div id='main-content' className='login-content-wrapper'>
					<form id="loginForm" className='admin-login-form' onSubmit={(e) => {handleForm(e);}}>
						<h1>Admin logg inn</h1>
						<span className="login-feedback">{feedback}</span>
						<div>
							<label htmlFor="username">Username:</label>
							<input 
								type="text" 
								name="username" 
								id="username" 
								required={true}
								autoComplete='username'
							/>
						</div>
						<div>
							<label htmlFor='password'>Password:</label>
							<input 
								type="password" 
								name="password" 
								id="password" 
								required={true}
								autoComplete='current-password'
							/>
						</div>
						<button 
							type='submit'
							className='login-btn'
						>
							Logg inn
						</button>
					</form>
				</div>
			</section>
		);
	};

	return (
		<section className='page page-login'>
			{
				renderLogin()
			}
		</section>
	);
};

export default Login;
