import React from 'react';

const Privacy = () => {

	return (
		<section id='main-content' className='page personvern-page'>
			<h2>Personverns Erklæring</h2>
			<section>
				<h3>Arrangør</h3>
				<p>
					Arrangørar som ønsker det, kan vise sine Facebookarrangement på denne nettstaden, 
					ved å logge seg på med Facebook og leggje til kode for sitt arrangement. 
					Ein applikasjon sørger for at informasjon vert henta frå Facebook og vist på nettsida. 
					Ingen persondata vert lagra, berre detaljar om arrangementet.
				</p>
				<h4>Sletting av data</h4>
				<p>
					Ingen persondata vert lagra, berre detaljar om arrangementet. For sletting av arrangement, vennligst ta kontakt med oss.
				</p>
			</section>

		</section>
	);
};

export default Privacy;