import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';

import EventCard from '../components/Events/EventCard';
import Events from '../components/Events/Events';
import { ApiRequest } from '../utilities/apiRequest';
import { device } from '../utilities/breakpoints';
import icon from '../assets/images/A-icon.svg';
import { EventContext } from '../context/EventContext';

const SingleEvent = () => {
	const context = useContext(EventContext);

	const [event, setEvent] = useState({});
	const { eventId, type } = useParams();

	useEffect(() => {
		if (type === 'af') { 
			let isSubscribed = true;

			const fetchData = async (id) => {
				const url = `https://api.aktiv-fritid.no/v2/events/${id}/anonymous`;
				const response = await ApiRequest(url);

				if (isSubscribed) {
					setEvent(response);
				}
			};
			fetchData(eventId)
				.catch(console.error);

			return () => isSubscribed = false;
		} else if (type === 'eb') {
			if (!context) {
				return;
			}

			if (context.events) {
				context.events.forEach(event => {
					if (`${event.id}` === eventId) {
						setEvent(event);
					}
				});
			}
		}
	}, [eventId, context]);

	const handleImage = () => {

		if (event.app_type === 'eb') {
			let image_url = '';
			const image = event?.production?.assets;
			if (image) {
				image.forEach(item => {
					if (item.type === 'poster') {
						image_url = item.url;
					}
				});
			}
			return image_url;
		}

		const eventImage = event.pictureLink;
		return eventImage;
	};

	const handleContent = () => {
		if (event.app_type === 'eb') {
			const contentArr = event?.production?.contents;

			if (!contentArr) { 
				return;
			}

			const content = contentArr[0]?.body;

			if (content) {
				return parse(content);
			}
		}
		if (event.informations) {
			const content = event.informations[0]?.information;
			return content;
		}
	};

	const handleCard = () => {
		let start = new Date(event.start);
		let end = new Date(event.end);

		if (event.app_type === 'eb') {
			start =  new Date(event.start * 1000);
			end = new Date(event.end);
		}

		if (start.getDate() !== end.getDate()) {
			event.isMultipleDays = true;
		} else {
			event.isMultipleDays = false;
		}

		if (event) {
			return (
				<EventCard
					event={event}
				/>
			);
		}
	};
    
	const handleVideo = () => {
		if (event?.videoLink) {
			return event.videoLink;
		}
	};

	return (
		<>
			<article id={event.id} className='single-event-article'>
				<div className="single-event-wrapper">
					<section id='main-content' className="single-event-header">
						<div className='single-event-image-wrapper'>
							<img className="background-image" src={handleImage()} alt="Bakgrunns bilde" />
							<img className="foreground-image" src={handleImage()} alt='Arrangement bilde' />
						</div>
						{handleCard()}
					</section>
					<section className="single-event-content">
						<img className="single-event-icon" src={icon} alt='' aria-hidden='true' />
						{
							event.app_type === 'eb'
								? <div>{handleContent()}</div>
								: <p>{handleContent()}</p>
						}
						{
							event?.videoLink
								? <a href={handleVideo()} target="_blank" rel="noreferrer">
									<p>Video</p>
								</a>
								: ''
						}
					</section>
				</div>
			</article>
			<Events />
		</>
	);
};

export default SingleEvent;