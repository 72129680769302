import React from 'react';

const PaginationButton = ({setCurrentPage, page, classes}) => {
	const gotoPage = (page) => {
		setCurrentPage(page);
	};

	return (
		<button
			onClick={() => gotoPage(page)}
			className={classes}
			type='button'
			name={`Side ${page}`}
		>
			{page}
		</button>
	);
};

export default PaginationButton;