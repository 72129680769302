import React, { useState, useEffect} from 'react';

import FacebookLogin from '../components/OrganizerArea/FacebookLogin';
import OrganizerArea from '../components/OrganizerArea/OrganizerArea';
import { dev } from '../utilities/devEnv'; 

const Organizer  = () => {
	const [isLoggedIn, setIsLoggedIn] = useState(null);

	// Load facebook api once on mount
	useEffect(() => {
		window.fbAsyncInit = function() {
			window.FB.init({
				appId      : '593055032415682',
				cookie     : true,
				xfbml      : true,
				version    : 'v14.0'
			});
		};
	
		checkLoginStatus();

		(function(d, s, id){
			var js, fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) {return;}
			js = d.createElement(s); js.id = id;
			js.src = 'https://connect.facebook.net/en_US/sdk.js';
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'facebook-jssdk'));
	}, []);

	const checkLoginStatus = async () => {
		try {
			const response = await fetch(`${dev}/events/action/check_fb_login_status`);
			const data = await response.json();
			setIsLoggedIn(data.isLoggedIn);
		} catch (error) {
			console.error('Error checking login status: ', error.message);
		}
	};

	const onLogin = async (accessToken) => {
		const url = `${dev}/events/action/get_fb_access_token`;
		try {
			const response = await fetch(url , {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ accessToken })
			});

			if (response.ok) {
				setIsLoggedIn(true);
			} else {
				console.error('Facebook login failed:', response.statusText);
			}
		} catch (error) {
			console.error('Error during Facebook login:', error.message);
		}
	};
	
	const handleLogout = () => {
		window.FB.getLoginStatus(function (response) {
			if (response.status === 'connected') {
				window.FB.logout(function (logoutResponse) {
					if (logoutResponse.status === 'unknown') {
						fetch(`${dev}/events/action/handle_fb_logout`, {
							method: 'GET'
						})
							.then(serverLogoutResponse => {
								if (serverLogoutResponse.ok) {
									setIsLoggedIn(false);
								} else {
									console.error('Server-side logout failed: ', serverLogoutResponse.statusText);
								}
							})
							.catch(error => {
								console.error('Error during server-side logout', error.message);
							});
					} else {
						console.error('Error during Facebook logout: ', logoutResponse);
					}
				});
			} else {
				setIsLoggedIn(false);
			}
		});

	};

	const renderOrganizerArea = () => {
		if (isLoggedIn === null) {
			<p>Loading...</p>;
		} else {
			if (isLoggedIn) {
				return (
					<OrganizerArea
						setIsFbLogin={setIsLoggedIn}
						isFbLogin={isLoggedIn}	
						handleLogout={handleLogout}
					/>
				);
			} else {
				return (
					<div>
						<FacebookLogin 
							isFbLogin={isLoggedIn}
							onLogin={onLogin}
						/>
					</div>	
				);
			}
		}
	};

	return (
		<section id='main-content' className='page organizer-page'>
			{
				renderOrganizerArea()
			}
		</section>
	);
};

export default Organizer;