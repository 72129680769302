import React from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
} from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';

import './App.scss';
import Home from './pages/Home';
import Suggest from './pages/Suggest';
import Nav from './components/Nav';
import SingleActivity from './pages/SingleActivity';
import SingleEvent from './pages/SingleEvent';
import { ApiRequest } from './utilities/apiRequest';
import Footer from './components/Footer';
import data from './utilities/data';
import NotFound from './pages/NotFound';
import Admin from './pages/Admin';
import { dev, urlDev } from './utilities/devEnv';
import ProtectedRoute from './components/ProtectedRoute';
import { EventContext } from './context/EventContext';

import Privacy from './pages/Privacy';
import Organizer from './pages/Organizer';
import Login from './pages/Login';
import ScrollToTop from './utilities/scrollToTop';

export default class App extends React.Component {
	constructor (props) {
		super(props);

		this.fetchData = this.fetchData.bind(this);
		this.noDuplicates = this.noDuplicates.bind(this);
		this.handleLanguage = this.handleLanguage.bind(this);
		this.handleLanguageChange = this.handleLanguageChange.bind(this);
		this.setAuthenticated = this.setAuthenticated.bind(this);
		this.setLoading = this.setLoading.bind(this);
		this.getSessionLogin = this.getSessionLogin.bind(this);
		this.setAdminEvents = this.setAdminEvents.bind(this);

		this.state = {
			Categories: [],
			Activities: [],
			Events: [],
			fbEvents: [],
			language: { value: 'nn', label: 'Nynorsk' },
			cookieText: {},
			pageText: {},
			componentText: {},
			isAuthenticated: false,
			loading: false,
		};

		sessionStorage.setItem('currentLanguage', JSON.stringify(this.state.language));
	}

	noDuplicates (arr) {
		const newArray = [];
		const uniqueObject = {};

		for (const i in arr) {
			const objId = arr[i].id;
			uniqueObject[objId] = arr[i];
		}

		for (const i in uniqueObject) {
			newArray.push(uniqueObject[i]);
		}

		return newArray;
	}

	async fetchData () {
		const urlContent = `https://aktivitetforalle.no/fritid-api/get_activities?language=${this.state.language.value}`;
		try {
			const activities = await ApiRequest(urlContent);
			if (activities && activities.categories && activities.activities) {
				this.setState({
					Categories: activities.categories,
					Activities: activities.activities,
				});
			} else {
				throw new Error('no content from api');
			}
		} catch (e) {
			console.log(e);
		}
	}

	handleLanguage () {
		const textContent = data;
		for (const [key, value] of Object.entries(textContent)) {
			if (this.state.language.value === key) {
				if (key === 'ar') {
					document.documentElement.dir = 'rtl';
				} else {
					document.documentElement.dir = 'ltr';
				}

				const text = value;
				this.setState({
					cookieText: text.cookie,
					pageText: text.pageData,
					componentText: text.components
				});
			}
		}
	}

	handleLanguageChange (language) {
		this.setState({ language: language }, () => {
			this.handleLanguage();
			this.fetchData();
		});
	}

	getSessionLogin () {
		const url = `${dev}/auth/action/session_login`;

		this.setLoading(true);
		fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(res => res.json())
			.then(data => {
				if (data.code === 200) {
					if (data.authenticated) {
						this.setAuthenticated(data.authenticated);
					}
					this.setLoading(false);
				}
			});
	}

	componentDidMount () {
		this.getSessionLogin();
		this.fetchData();
		this.handleLanguage();

		const selectedLanguage = JSON.parse(sessionStorage.getItem('currentLanguage'));
		if (selectedLanguage !== null) {
			this.setState({ language: selectedLanguage });
		}
	}

	setAuthenticated (auth) {
		this.setState({ isAuthenticated: auth });
	}

	setLoading (loading) {
		this.setState({ loading: loading });
	}

	setAdminEvents (events) {
		this.setState({ Events: events });
	}

	render () {
		return (
			<Router>
				<div id="skipnav">
					{/* <p className="skipnav">
						<a href="#main-content">Gå til hovedinnhald ( Trykk Enter )</a>
					</p>
					<p className="skipnav">
						<a href="#skip-events">Gå til arrangement ( Trykk Enter )</a>
					</p>
					<p className="skipnav">
						<a href="#skip-categories">Gå til aktiviteter ( Trykk Enter )</a>
					</p>
					<p className="skipnav">
						<a href="#skip-suggest">Gå til forslag ( Trykk Enter )</a>
					</p>
					<p className="skipnav">
						<a href="#skip-other">Gå til andre ressurser ( Trykk Enter )</a>
					</p> */}
				</div>
				<Nav text={this.state.componentText} />
				<main className="app">
					<ScrollToTop />
					<Switch>
						<Route exact path={`${urlDev}`}>
							<Home
								categories={this.state.Categories}
								allActivities={this.state.Activities}
								language={this.state.language}
								pageText={this.state.pageText}
								componentText={this.state.componentText}
								events={this.state.Events}
								fbEvents={this.state.fbEvents}
							/>
						</Route>
						<Route path={`${urlDev}suggest`}>
							<Suggest
								language={this.state.language}
								pageText={this.state.pageText}
								componentText={this.state.componentText}
							/>
						</Route>
						<Route path={`${urlDev}activity/:activityId`}>
							<SingleActivity
								categories={this.state.Categories}
								allActivities={this.state.Activities}
								language={this.state.language}
								componentText={this.state.componentText}
							/>
						</Route>
						<Route path={`${urlDev}event/:eventId/:type?`}>
							<SingleEvent />
						</Route>
						<ProtectedRoute
							isAuthenticated={this.state.isAuthenticated}
							path={`${urlDev}admin`}
						>
							<Admin 
								componentText={this.state.componentText}
								isAuthenticated={this.state.isAuthenticated}
								setAuthenticated={this.setAuthenticated}
								loading={this.state.loading}
								setLoading={this.setLoading}
							/>	
						</ProtectedRoute>
						<Route
							path={`${urlDev}login`}
						>
							<Login 
								isLoading={this.state.loading}
								setLoading={this.setLoading}
								setAuthenticated={this.setAuthenticated}
								isAuthenticated={this.state.isAuthenticated}
							/>
						</Route>
						<Route
							path={`${urlDev}arrangor`}
						>
							<Organizer />
						</Route>
						<Route
							path={`${urlDev}privacy`}
						>
							<Privacy />
						</Route>
						<Route path="*" component={NotFound}/>
					</Switch>
					<CookieConsent
						location="bottom"
						buttonText={this.state.cookieText.button}
						style={{ background: '#fff', color: '#000', fontSize: '0.9em' }}
						buttonStyle={{ background: '#000', color: '#fff' }}
					>
						{this.state.cookieText.text}
					</CookieConsent>
				</main>
				<Footer
					componentText={this.state.componentText}
				/>
			</Router>
		);
	}
}

App.contextType = EventContext;