import React from 'react';

import AdminOrganizerEvent from './AdminOrganizerEvent';

const AdminEventList = ({filteredEvents, handleReset, setFeedback, setFilteredEvents, excludedEvents, getExcludedEvents }) => {
	const sortEventsIntoOrganizer = (arr) => {
		if (!arr.length) {
			return;
		}

		// Get all organizers
		const subArr = arr.map(event => {
			if (event.app_type === 'fb') {
				event.service = {};
				event.service.id = '99999';	
			} else if (event.app_type === 'eb') {
				event.service = {};
				event.service.id = event.partner_id;
			}
			
			return event.service.id;
		});

		// Remove duplicates from subArr
		const removeDuplicates = subArr.filter((event, index) => {
			return subArr.indexOf(event) === index;
		});

		// Make all organizer keys
		const arrObj = removeDuplicates.reduce((acc, cur) => ({...acc, [cur]: []}), {});

		// Add events to their organizer
		arr.forEach(event => {
			if (arrObj[event.service.id]) {
				arrObj[event.service.id].push(event);
			}
		});

		// Return events sorted into their arrangers
		return arrObj;
	};

	const renderList = () => { 
		let sortedEvents = []; 

		sortedEvents = sortEventsIntoOrganizer(filteredEvents);

		if (!filteredEvents.length) {
			return (
				<>
					<p>Ingen arrangement funnet</p>
					<button
						type='button'
						onClick={() => handleReset()}
						title='Tilbakestill arrangement'
					>
						Tøm filter
					</button>
				</>
			);
		}

		// All organizers
		const keys = Object.keys(sortedEvents);

		// Render all events beneath a organizer name
		const allEvents = keys.map((key, index) => {
			let organizerName = sortedEvents[key].map(event => {
				switch (event.app_type) {
					case 'eb':
						if (event.partner_id === 283) {
							event.service.name = 'E-billett Fitjar';
							return 'E-billett Fitjar';
						} else if (event.partner_id === 169) {
							event.service.name = 'E-billett Stord';
							return 'E-billett Stord';
						} else if (event.partner_id === 121) {
							event.service.name = 'E-billett Bømlo';
							return 'E-billett Bømlo';
						}
						break;
					case 'af':
						if (event.service.id === Number(key)) {
							return event.service.name;
						}
						break;
					case 'fb':
						event.service.name = 'Facebook Arrangement';
						return event.service.name;
				}
			});

			organizerName = organizerName.filter(event => {
				return event !== undefined;
			});

			organizerName = [...new Set(organizerName)]; // Remove duplicate names
			
			return (
				<li className='admin-event-list-key' key={`${key}_${index}`}>
					<AdminOrganizerEvent
						organizer={organizerName}
						events={sortedEvents[key]}
						filteredEvents={filteredEvents}
						setFeedback={setFeedback}
						setFilteredEvents={setFilteredEvents}
						excludedEvents={excludedEvents}
						getExcludedEvents={getExcludedEvents}
					/>
				</li>
			);
		});

		return allEvents;
	};

	return (
		<div className='admin-events'>
			<h2>Arrangører</h2>
			<ul className='admin-event-list'>
				{
					renderList()
				}
			</ul>
		</div>
	);
};

export default AdminEventList;
 

