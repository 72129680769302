import React from 'react';
import Helmet from 'react-helmet';

import Thumbnail from '../assets/images/Frontpage-img.png';
import Logo from '../assets/images/logo-fritidskort.png';
import LogoStord from '../assets/images/kommune-stord.svg';
import LogoBomlo from '../assets/images/kommune-bomlo.svg';
import LogoFitjar from '../assets/images/kommune-fitjar.svg';

const siteName = 'Aktivitet for alle';

export const SEO = ({
	title,
	type,
	description,
	location = ''
}) => {
	const structuredDataOrganization = `{
       "@context": "http://schema.org",
       "@type": "${type}",
       "url": "https://aktivitetforalle.no${location}",
       "logo": "${Logo}",
       "name": "${siteName}",
       "publisher": [{
            "@type": "Organization",
            "name": "Stord Kommune",
            "logo": {
                "@type": "ImageObject",
                "url": "${LogoStord}"
            }
        },
        {
            "@type": "Organization",
            "name": "Bømlo Kommune",
            "logo": {
                "@type": "ImageObject",
                "url": "${LogoBomlo}"
            }
        },
        {
            "@type": "Organization",
            "name": "Fitjar Kommune"
            "logo": {
                "@type": "ImageObject",
                "url": "${LogoFitjar}"
            }
        }]
    }`;

	return (
		<Helmet>
			<title>{title}</title>
			<meta name="description" content={description} />
			<meta name="image" content={`https://aktivitetforalle.no${Thumbnail}`}/>
			<script type="application/ld+json">
				{structuredDataOrganization}
			</script>
		</Helmet>
	);
};
