import React from 'react';

const SettingsOverview = () => {
	return (
		<div>
			<h1>Innstillingsoversikt</h1>
			<p>Her kan du lage ny bruker eller endre passord på din eksisterende bruker.</p>
		</div>
		
	);
};

export default SettingsOverview;