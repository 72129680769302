import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import PageNumbers from './PageNumbers';

const EventPagination = ({ currentPage, setCurrentPage, maxPage, children }) => {
	const prevPage = () => {
		if (currentPage === 1) {
			return;
		}
		setCurrentPage(currentPage - 1);
	};

	const nextPage = () => {
		if (currentPage === maxPage) {
			return;
		}
		setCurrentPage(currentPage + 1);
	};

	const renderPagination = () => {
		return (
			<div className='event-pagination'>
				<button
					onClick={() => prevPage()}
				>
					{<FontAwesomeIcon icon={faChevronLeft} />}
				</button>
				<div className='pagination-number-wrapper'>
					<PageNumbers currentPage={currentPage} setCurrentPage={setCurrentPage} maxPage={maxPage}/>
				</div>
				<button
					onClick={() => nextPage()}
				>
					{<FontAwesomeIcon icon={faChevronRight} />}
				</button>
			</div>
		);
	};

	return (
		<>
			{children}	
			{renderPagination()}
		</>
	);
};

export default EventPagination;