import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

import { faChevronRight } from '@fortawesome/pro-light-svg-icons';

import AdminAreaFilters from './AdminAreaFilters';

const AdminFilters = ({ 
	filters, 
	setFilters, 
	handleFilters, 
	setFilteredEvents, 
	handleReset, 
	locations,
	setLocations,
	fromDate,
	setFromDate
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleOpen = () => {
		setIsOpen(!isOpen);

		if (!isOpen) {
			handleReset();
		}
	};

	useEffect(() => {
		if (filters.searchInput !== '') {
			setIsOpen(false);
		}
	}, [filters.searchInput]);

	return (
		<div className='admin-filters'>
			<button 
				className='filter-btn' 
				type={'button'} 
				onClick={() => {handleOpen();}}
				style={{ 
					borderBottomLeftRadius: isOpen ? '0' : '31px' ,
					borderBottomRightRadius: isOpen ? '0' : '31px' 
				}}
				title='Opne filter meny'
			>
				<p>Filter</p>
				<FontAwesomeIcon icon={faChevronRight} style={{ transform: isOpen ? 'rotate(90deg)' : ''}}/>
			</button>
			{
				isOpen &&
				<AdminAreaFilters 
					setFilters={setFilters}
					filters={filters}
					handleFilters={handleFilters}
					setFilteredEvents={setFilteredEvents}
					handleReset={handleReset}
					locations={locations}
					setLocations={setLocations}
					fromDate={fromDate}
					setFromDate={setFromDate}
				/>
			}
		</div>
	);
};

export default AdminFilters;