import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { dev } from '../../utilities/devEnv';
import AdminOrganizerEventItem from './AdminOrganizerEventItem';

const AdminOrganizerEvent = ( props ) => {
	const [active, setActive] = useState(false);

	const toggleEvents = () => {		
		setActive(!active);
	};

	useEffect(() => {
		if (props.filteredEvents.length <= 10) {
			setActive(true);
		}
	}, [props.filteredEvents]);

	const handleExclusion = (show, event) => {
		let data = new FormData();

		if (show === 'exclude') {
			data.append('event-id', event);
			data.append('exclude', 'exclude');	
		} else if (show === 'show') {
			data.append('event-id', event);
			data.append('exclude', 'show');
		}

		const url = `${dev}/events/action/toggle_exclude`;

		fetch(url, {
			method: 'POST',
			headers: {
				'x-api-key': '071bb2abbfb9d54bb7f71da320183e',
				'Accept': 'application/json'
			},
			body: data,
		})
			.then(res => res.json())
			.then((data) => {
				if (data.code === 200) {
					props.setFeedback(data.feedback);
					props.getExcludedEvents();
				}
			});
	};

	return (
		<>
			<button
				className='organizer-btn'
				type="button"
				name="orgbtn"
				onClick={toggleEvents}
				style={{
					borderBottom: active ? 'none' : '',
					borderBottomLeftRadius: active ? '0' : '6px',
					borderBottomRightRadius: active ? '0' : '6px'
				}}
				title='Vis arrangment'
			>
				<p>{props.organizer}</p>
				<FontAwesomeIcon 
					icon={faChevronRight} 
					style={{
						transform: active ? 'rotate(90deg)' : '' 
					}}
				/>
			</button>
			{
				active ?
					<table
						className='admin-event-list-values'
						style={{
							backgroundColor: active ? '#B4D8AD' : '',
							border: active ? '1px solid #707070': '',
							borderTop: active ? 'none' : '',
							borderBottomLeftRadius: active ? '6px' : '0',
							borderBottomRightRadius: active ? '6px' : '0',
						}}
					>
						<thead>
							<tr>
								<td>Arrangement:</td>
								<td>Dato:</td>
								<td>Skjul / Vis</td>
							</tr>
						</thead>
						<tbody>
							{
								props.events.map(event => {
									return (
										<tr key={event.id}>
											{
												<AdminOrganizerEventItem 
													handleExclusion={handleExclusion}
													event={event}
													excludedEvents={props.excludedEvents}
												/>
											}
										</tr>
									
									);
								})
							}
						</tbody>
					</table>
					:
					null
			}
		</>
	);
};

export default AdminOrganizerEvent;