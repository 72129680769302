import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';

import Card from '../components/Activities/Card.js';
import Categories from '../components/Activities/Categories.js';
import icon from '../assets/images/A-icon.svg';
import { device } from '../utilities/breakpoints';
import { ApiRequest } from '../utilities/apiRequest';
import logo from '../assets/images/bildemangler.jpg';
import NotFound from '../pages/NotFound.js';
import { SEO } from '../components/Seo.js';

class SingleActivity extends React.Component {
	constructor (props) {
		super(props);

		this.fetchData = this.fetchData.bind(this);
		this.handleInfo = this.handleInfo.bind(this);
		this.handleMissingImage = this.handleMissingImage.bind(this);
		this.handleVideo = this.handleVideo.bind(this);
		this.handleImgAlt = this.handleImgAlt.bind(this);

		this.state = {
			results: {},
			isLoading: false
		};
	}

	componentDidMount () {
		const id = this.props.match.params.activityId;
		this.fetchData(id);
	}

	componentDidUpdate (prevProps) {
		if (this.props.language.value !== prevProps.language.value) {
			this.fetchData(this.props.match.params.activityId);
		}
		if (this.props.match.params.activityId !== prevProps.match.params.activityId) {
			this.fetchData(this.props.match.params.activityId);
		}
	}

	async fetchData (id) {
		this.setState({ isLoading: true });

		const url = `https://api.aktiv-fritid.no/v2/services/${id}/anonymous?informationLevel=1&languageCode=${this.props.language.value}`;
		const response = await ApiRequest(url);

		if (response && response.informations) {
			const pattern = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/g;
			const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

			response.informations = response.informations.map((info) => {
				// change to html
				let text = '<p>' + info.information + '</p>';
				text = text.replace(/(\n\s)|(\n\s\w)/g, '</p><p>');

				const links = text.match(pattern);
				const email = text.match(emailRegex);

				if (email) {
					email.forEach(email => {
						text = text.replaceAll(email, `<a href="mailto:${email}">${email}</a>`);
					});
				}
				if (links) {
					links.forEach(link => {
						let newLink;

						if (!link.startsWith('https')) {
							newLink = 'https://' + link;
							text = text.replaceAll(link, `<a target="_blank" rel="noreferrer" href="${newLink}">${link}</a>`);
						} else {
							newLink = link;
							text = text.replaceAll(link, `<a tearget="_blank" rel="noreferrer" href="${newLink}">${link}</a>`);
						}
						links.splice(link, 1);
					});
				}
				info.information = text;
				return info;
			});
		}
		this.setState({ results: response, isLoading: false });
	}

	handleMissingImage () {
		const { results } = this.state;
		if (!results || !results.pictureLink) {
			return;
		}
		if (this.state.results.pictureLink ===
        'https://activeleisurestorage.blob.core.windows.net/activeleisurecontainer/Picture_620x400/BildeMangler.png') {
			return logo;
		} else {
			return this.state.results.pictureLink;
		}
	}

	handleInfo () {
		const { results } = this.state;
		const value = ((results || {}).serviceProvider || {}).name;
		const name = ((results || {}).geo || {}).name;

		return `${value} - ${name}`;
	}

	handleVideo () {
		const { results } = this.state;
		if (results?.videoLink) {
			return results.videoLink;
		}
	}

	handleImgAlt() { 
		if (this.state.results.pictureLink === 
			'https://activeleisurestorage.blob.core.windows.net/activeleisurecontainer/Picture_620x400/BildeMangler.png') 
		{
			return 'Bilde mangler';
		} else {
			return 'Aktivitet bilete';
		}
	}

	render () {
		const { results } = this.state;
		const categoryText = (this.props.componentText || {});
		const cardText = ((this.props.componentText || {}).card || {});

		const renderExcerpt = () => {
			const info = this.state.results.informations || [];
			return info.map(info => {
				if (info.teaser) {
					return <Excerpt key='content-excerpt'>{info.teaser}</Excerpt>;
				}
			});
		};

		const activityId = parseInt(this.props.match.params.activityId);

		const renderCategory = () => {
			const {allActivities} = this.props;
			const {results} = this.state;
			const parts = [];
			if (allActivities && results) {
				const activity = this.props.allActivities.find((a) => a.id === results.id);
				if (activity) {
					if (activity.serviceType) {
						parts.push(<p key="cat">Kategori: <strong>{activity.serviceType.category.name.value}</strong></p>);
					}
					const adapt = [];
					activity.targetGroups.forEach((grp) => {
						if (grp.id !== 15) {
							adapt.push(grp.name.value);
						}
					});
					if (adapt.length) {
						parts.push(<p key="adapt">Tilrettelagt for: <strong>{adapt.join(', ')}</strong></p>);
					}
				}
			}
			return (
				<div>{parts}</div>
			);
		};

		if (this.state.isLoading) return <div style={{ height: '48em' }}></div>;
		if (activityId !== this.state.results.id) {
			return <NotFound />;
		} else {
			return (
				<Wrapper id='main-content'>
					<SEO title={this.state.results.name} location="/activity/:activityId" type="WebPage" description={renderExcerpt()}/>
					<ActivityHeader>
						<ImgWrapper img={this.handleMissingImage()} alt={this.handleImgAlt()}>
						</ImgWrapper>
						<CardWrapper>
							<Card
								content={this.state.results}
								componentText={cardText}
							/>
						</CardWrapper>
					</ActivityHeader>
					<Main>
						<TextWrapper>
							<TextHeaderContainer>
								<Icon src={icon} alt='' aria-hidden='true' />
								<HeaderWrapper>
									<SubHeader>{this.handleInfo()}</SubHeader>
									<Header>{this.state.results.name}</Header>
								</HeaderWrapper>
							</TextHeaderContainer>
							<Category>{renderCategory()}</Category>
							{renderExcerpt()}
							<div dangerouslySetInnerHTML={{ __html: this.state.results.informations ? this.state.results.informations[0].information : '<p>Mangler tekst</p>' }} />
							{ results?.videoLink
								? <VideoLink href={this.handleVideo()} target="_blank" rel="noreferrer">
									<p>Video</p>
								</VideoLink>
								: ''
							}
						</TextWrapper>
					</Main>
					<CategoryWrapper>
						<Categories
							categories={this.props.categories}
							allActivities={this.props.allActivities}
							componentText={categoryText}
							language={this.props.language}
						/>
					</CategoryWrapper>
				</Wrapper>
			);
		}
	}
}

export default withRouter(SingleActivity);

const Wrapper = styled.section`
        margin: 0 0 4em;

        @media ${device.biglaptop} {
            margin:0 4em 4em;
        }
    `;
const ImgWrapper = styled.div`
        display: flex;
        justify-content: center;
        width: 757px;
        height: 640px;
        background: url(${props => props.img}) transparent no-repeat scroll center / cover;
        overflow: hidden;
        border-bottom-left-radius: 50px;
		border-top-left-radius: 50px;

        &:dir(rtl) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 50px;
        }

        @media ${device.biglaptop}{
            &:dir(rtl) {
                border-top-left-radius: 0;
                border-top-right-radius: 50px;
            }
        }

        @media ${device.mobile} {
            margin: 0 auto;
            height: 45vh;
            width: 100%;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }
    `;
const Main = styled.section`
        padding: 4em 4em 0;
        display: flex;
        justify-content: space-between;

        @media ${device.mobile}{
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 2em 1em;
        }
    `;
const TextWrapper = styled.article`
        margin: 0 2em;
        max-width: 800px;

        @media ${device.laptop}{
            min-width: 350px;	
        }
        @media ${device.mobile}{
            width: unset;
            margin: 0;
            max-width: 600px;
            min-width: unset;
        }
    `;
const TextHeaderContainer = styled.div`
        display: flex;
        align-items: center;

        @media ${device.mobile}{
            display: block;
        }
    `;
const CardWrapper = styled.section`
        @media ${device.mobile}{
            width: 100%;
        }
    `;
const CategoryWrapper = styled.div`
        margin: 8em 0 0;

        @media ${device.mobile} {
            margin: 2em 0 0;
        }
    `;
const HeaderWrapper = styled.div`
        display: block;
    `;
const Icon = styled.img`
        height: 41px;
        width: 41px;
        margin-left: -81px;
        margin-right: 40px;
        align-self: end;

        &:dir(rtl) {
            margin-left: 40px;
            margin-right: -81px;
        }

        @media ${device.mobile}{
            margin: 0;
        }
    `;
const SubHeader = styled.h2`
        font-weight: 500;
        font-size: 1em;
        margin-top: 0;
        margin-bottom: 0;
    `;
const Header = styled.h1`
        margin: 0;
    `;
const Excerpt = styled.p`
        font-weight: bold;
        margin: 0 0 1em;
    `;
const ActivityHeader = styled.section`
        display: flex;
        margin: 0 auto;
        justify-content: center;

        @media ${device.mobile} {
            flex-direction: column;
        }
    `;
const Category = styled.div`
        margin: 0 0 2em;
    `;
const VideoLink = styled.a`
    `;