import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { urlDev } from '../utilities/devEnv';

const ProtectedRoute = ({children, isAuthenticated, ...props}) => {
	return (
		isAuthenticated
			? <Route {...props}>{children}</Route>
			: <Redirect to={`${urlDev}login`} />
	);
};
export default ProtectedRoute;