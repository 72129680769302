import { token } from './token';

export async function ApiRequest (url) {
	let key = atob(token);
	if (url.indexOf('fritid-api') !== -1) {
		key = '071bb2abbfb9d54bb7f71da320183e';
	}

	const response = await fetch(url,
		{
			method: 'GET',
			headers: {
				'x-api-key': key,
				Accept: 'application/json',
			}
		}
	);

	const json = await response.json();
	return json;
}
