const size = {
	smMobile: '400px',
	mobile: '900px',
	tablet: '1100px',
	laptop: '1400px',
	biglaptop: '1560px',
	desktop: '2560px'
};

export const device = {
	smMobile: `(max-width: ${size.smMobile})`,
	mobile: `(max-width: ${size.mobile})`,
	tablet: `(max-width: ${size.tablet})`,
	laptop: `(max-width: ${size.laptop})`,
	biglaptop: `(max-width: ${size.biglaptop})`,
	desktop: `(max-width: ${size.desktop})`
};
