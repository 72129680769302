import React, { useState, useEffect, useRef, useContext } from 'react';

import AdminFilters from './AdminFilters.js';
import AdminSearch from './AdminSearch.js';
import AdminEventList from './AdminEventList.js';
import { dev } from '../../utilities/devEnv.js';
import AdminAreaApplications from './AdminAreaApplications.js';
import postNr from '../../utilities/postNr.js';
import { EventContext } from '../../context/EventContext.js';

const AdminArea = ({ setAuthenticated, setFeedback, setSettings, settings }) => {
	const context = useContext(EventContext);

	const [filteredEvents, setFilteredEvents] = useState([]);
	const [filters, setFilters] = useState({
		locations: [],
		type: '',
		date: null,
		searchInput: ''
	});
	const [locations, setLocations] = useState([]);
	const [fromDate, setFromDate] = useState([]);
	const [excludedEvents, setExcludedEvents] = useState([]);
	const [applications, setApplications] = useState([]);

	const loadEvents = useRef(true);
	useEffect(() => {
		if (loadEvents.current && context) {
			setFilteredEvents(context.events);
			setApplications(context.applications);
			loadEvents.current = false;
		}	
	}, [context]);

	useEffect(() => {
		const settings = JSON.parse(sessionStorage.getItem('settings'));

		if (settings) {
			setSettings(settings);
		}
	}, []);

	const adminLogout = () => {
		const url = `${dev}/auth/action/logout`;

		fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			}
		})
			.then((res) => {
				if (res.status === 200) {
					return res.json();
				} else {
					return console.log('Server error: ', res.status);
				}
			})
			.then(data => {
				if (data.code === 200) {
					if (!data.authenticated) {
						setAuthenticated(data.authenticated);
					}
				}
			});

	};

	const handleFilters = (e) => {
		e.preventDefault();

		if (!context) {
			return;
		}

		doFilters(context.events, filters);
	};

	const doFilters = (events, filter = {}) => {
		let eventsToReturn = [];

		if (filter.locations.length) {
			const filterEvents = (event) => {
				let bool = false;
				const searchWords = {
					'stord': ['stord', 'leirvik', 'solid'],
					'bomlo': ['bømlo', 'bremnes', 'svortland', 'rubbestadneset', 'langevåg'],
					'fitjar': ['fitjar']
				};
				const keys = Object.keys(searchWords);
				const locations = filter.locations;

				if (event.app_type === 'fb') {
					keys.forEach(key => {
						if (locations.indexOf(key) !== -1) {
							postNr[key].forEach(postnr => {
								if (event.app_place_name.toLowerCase().includes(postnr + '')) {
									bool = true;
									return;
								}
							});

							searchWords[key].forEach(word => {
								if (event.app_place_name.toLowerCase().includes(word)) {	
									bool = true;
									return;
								}
							});
						}
					});
				} 

				if (event.app_type === 'eb') {
					locations.forEach(location => {
						if (location === 'fitjar') {
							if (event.partner_id === 283) {
								bool = true;
								return;
							}
						}
						if (location === 'stord') {
							if (event.partner_id === 169) {
								bool = true;
								return;
							}
						}
						if (location === 'bomlo') {
							if (event.partner_id === 121) {
								bool = true;
								return;
							}	
						}
					});
				} 
				
				if (event.app_type === 'af') {
					keys.forEach(key => {
						if (locations.indexOf(key) !== -1) {
							searchWords[key].forEach(word => {
								if (event.geo.name.toLowerCase().includes(word)) {
									bool = true;
								}
							});
						}
					});
				}

				return bool;
			};

			eventsToReturn = events.filter(filterEvents);
		}

		if (filter.type) {
			const currentType = filter.type;
			eventsToReturn = events.filter(event => {
				if (currentType === event.app_type) {
					return event;
				}
			});
		}

		if (filter.searchInput) {
			const { searchInput } = filters;

			eventsToReturn = events.filter(event => {
				let bool = false;

				if (event.service.name.toLowerCase().includes(searchInput.toLowerCase())) {
					bool = true;
				}
				if (event.app_type === 'fb') {
					if (event.app_name.toLowerCase().includes(searchInput.toLowerCase())) {
						bool = true;	
					}
				}
				if (event.app_type === 'eb') {
					if (event.title.toLowerCase().includes(searchInput.toLowerCase())) {
						bool = true;
					}
				}
				if (event.app_type === 'af') {
					if (event.titles[0].value.toLowerCase().includes(searchInput.toLowerCase())) {
						bool = true;
					}
				}

				return bool;
			});
		}

		// ? Viser arrangement fra valgt dato
		if (filter.date) {
			const { date } = filter;

			eventsToReturn = events.filter(event => {
				let startDate = new Date(event.start * 1000);
				let bool = false;

				if (event.app_type === 'fb') {
					startDate = event.date;	
				}

				if (startDate >= date) {
					bool = true;
				}

				return bool;
			});
		}

		if (!checkFilters()) {
			eventsToReturn = events;
		}

		setFilteredEvents(eventsToReturn);
	};

	// useeffect for search
	useEffect(() => {
		if (!context) {
			return;
		}

		if (filters.searchInput !== '') {
			doFilters(context.events, filters);
		}
	}, [filters]);

	const checkFilters = () => {
		const {locations, type, date, searchInput} = filters;
		let bool = false;

		if (locations.length || type || date || searchInput) {
			bool = true;
		} 

		return bool;
	};

	const handleReset = () => {
		if (!checkFilters) {
			return;
		}

		setFilters({
			locations: [],
			type: '',
			date: null,
			searchInput: ''
		});
		setLocations([]);
		setFromDate(null);
		if (context) {
			setFilteredEvents(context.events);
		}

		const search = document.getElementById('admin-search');
		if (search) {
			search.value = '';
		}

		const bomlo = document.getElementById('bomlo');
		const fitjar = document.getElementById('fitjar');
		const stord = document.getElementById('stord');

		const allCheckboxes = [bomlo, fitjar, stord];
		allCheckboxes.forEach(box => {
			if (box !== null) {
				box.checked = false;
			}
		});

		const selectElement = (id, valueToSelect) => {
			let element = document.getElementById(id);
			if (element !== null) {
				element.value = valueToSelect;
			}
		};

		selectElement('type', '""');
	};

	const renderApplications = () => {
		if (context === null) {
			return;
		}

		if (context.applications.length) {
			return (
				<>
					<AdminAreaApplications 
						applications={applications}
						setApplications={setApplications}
						setFeedback={setFeedback}
					/>
				</>
			);
		}
	};

	const renderEventList = () => {
		if (context === null) {
			return;
		}

		if (context.events.length) {
			return (
				<AdminEventList
					filteredEvents={filteredEvents}
					filters={filters}
					handleReset={handleReset}
					setFeedback={setFeedback}
					setFilteredEvents={setFilteredEvents}
					setExcludedEvents={setExcludedEvents}
					excludedEvents={excludedEvents}
					getExcludedEvents={getExcludedEvents}
				/>
			);
		}
	};

	// useEffect(() => {
	// 	return (
	// 		<Portal
	// 			id='feedback-root'
	// 		>
	// 			<FeedBackModal message={feedback} />
	// 		</Portal>
	// 	);
	// }, [feedback]);

	const getExcludedEvents = async (signal) => {
		const url = `${dev}/events/action/get_is_excluded`;

		try {
			await fetch(url, {
				method: 'GET',
				signal: signal,
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'x-api-key': '071bb2abbfb9d54bb7f71da320183e'
				}
			})
				.then(res => res.json())
				.then((data) => {
					if (data.code === 200) {
						if (data.excluded.length) {
							const excludedIds = [];

							data.excluded.forEach(event => {
								excludedIds.push(event.event_id);
							});

							if (excludedIds.length) {
								setExcludedEvents(excludedIds);
							}
						} 
					} else {
						return;
					}
				}); 
		} catch (e) {
			if (e.name === 'AbortError') return;
			console.log('Error: ', e);
		}
	};

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		if (!settings) {
			getExcludedEvents(signal);
		}

		return () => {
			// cancel http request before component unmounts
			controller.abort();
		};
	}, []);

	return (
		<section className="page admin-panel">
			<div className='admin-area'>
				<div className='admin-content'>
					<h1>Administratorområde</h1>
					<AdminFilters
						filters={filters}
						setFilters={setFilters}
						handleFilters={handleFilters}
						setFilteredEvents={setFilteredEvents}
						handleReset={handleReset}
						locations={locations}
						setLocations={setLocations}
						fromDate={fromDate}
						setFromDate={setFromDate}
					/>
					<AdminSearch 
						setFilters={setFilters}
						filters={filters}
						handleReset={handleReset}
					/>
				</div>
				<div className='admin-welcome-msg'>
					<aside>
						Hei,
						Her kan du søke, filtrere, flytte, og ekskludere arrangement.
					</aside>
					<div className='admin-nav'>
						<button
							name='toggle-settings'
							className='settings-toggle'
							onClick={() => setSettings(true)}
							title='Gå til instillinger'
						>
							<p>Innstillinger</p>
						</button>
						<button 
							className='logout-btn'
							onClick={() => {adminLogout();}}
							title='Logg ut'
						>
							<p>Logg ut</p>	
						</button>
					</div>
				</div>
			</div>
			<div>
				{
					renderEventList()
				}
			</div>
			<div className='applications-wrapper'>
				{
					renderApplications()
				}	
			</div>
		</section>
	);
};

export default AdminArea;