import React from 'react';
import EventItem from './EventItem';

const EventsList = ({ currentEvents, currentPage, resetEvents }) => {
	const splitEvents = (arr, perPage) => {
		const res = [];	

		if (!arr.length) {
			return;
		} 
		
		while(arr.length) {
			res.push(arr.splice(0, perPage));
		}
		
		return res;
	};


	const renderList = () => {
		const allEvents = [...currentEvents];
		const split = splitEvents(allEvents, 6);

		if (split) {
			const events = split[currentPage - 1].map((event, index) => {
				return <EventItem key={`event-${index}`} event={event} />;
			});

			return (
				<div className='event-items-container'>{events}</div>
			);

		} else {
			return (
				<div className='no-events'>
					<h2>Ingen arrangement funnet</h2>
					<button
						onClick={() => resetEvents()}
					>
						Nullstill
					</button>
				</div>
			);
		}
	};

	return renderList(); 
};

export default EventsList;