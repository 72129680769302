import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import AddActivities from '../components/AddActivities/AddActivities';
import icon from '../assets/images/A-icon.svg';
import { device } from '../utilities/breakpoints';
import AddActivityForm from '../components/AddActivities/AddActivityForm';
import image from '../assets/images/suggest-img.jpg';
import { SEO } from '../components/Seo';

const modalroot = document.getElementById('modal-root');

export default class Suggest extends React.Component {
	render () {
		const renderAddActivites = () => {
			const element = (
				<Background>
					<AddActivities
						className="nobutton big-btn green-button"
						componentText={addActivitiesText}
						title='Gå til forslag til ny aktivitet'
					/>
				</Background>
			);
			return ReactDOM.createPortal(element, modalroot);
		};

		const pageText = ((this.props.pageText || {}).suggest || {});
		const addActivityFormText = ((this.props.componentText || {}).addActivityForm || {});
		const addActivitiesText = ((this.props.componentText || {}).addActivities || {});

		return (
			<Wrapper id='main-content'>
				<SEO title="Forslag til andre aktiviterar" location="/suggest" type="WebPage" description="Er det ein aktivitet du har kjempelyst å starte med og kanskje mange andre også syns er gøy?"/>
				<Container>
					<TextWrapper>
						<HeaderWrapper>
							<Icon src={icon} aria-hidden='true' alt='' />
							{pageText.header}
						</HeaderWrapper>
						<Article>
							{pageText.text}
						</Article>
						<AddActivityForm
							componentText={addActivityFormText}
						/>
					</TextWrapper>
					<ImageWrapper>
						<Image alt='' aria-hidden='true' />
					</ImageWrapper>
				</Container>
				{renderAddActivites()}
			</Wrapper>
		);
	}
}

const Wrapper = styled.div`
		@media ${device.laptop}{
			padding: 0 2em;	
		}
		@media ${device.mobile}{
			padding: 0;
		}
    `;
const Container = styled.section`
		display: flex;
		justify-content: space-between;
		padding: 4em 0 0;

		@media ${device.laptop}{
			flex-direction: column;
		}
		@media ${device.laptop}{
			padding: 0;
		}
    `;
const TextWrapper = styled.div`
		display: flex;
		flex-direction column;
		width: 33em;

		@media ${device.laptop}{
			order: 2;
			width: unset;
            margin-right: 188px;
            margin-top: 4em;
            align-self: center;
		}
		@media ${device.mobile}{
			width: unset;
            margin-right: unset;
            padding: 0 3em
		}
    `;
const HeaderWrapper = styled.div`
		display: flex;
		align-items: center;

		@media ${device.laptop}{
				
		}
		@media ${device.mobile}{
			display: block;
		}
    `;
const Article = styled.article`
		margin-left: 61px;

        @media ${device.laptop} {
            max-width: 600px;
        }

		@media ${device.mobile}{
			margin-left: 0;
		}
    `;
const Icon = styled.img`
        height: 41px;
		width: 41px;
		margin-right: 20px;

        &:dir(rtl) {
            margin-right: 0;
            margin-left: 20px;
        }
    `;
const ImageWrapper = styled.div`
		align-self: baseline;
		display: flex;
        justify-content: center;
        width: 500px;
        background: url(${image}) transparent no-repeat scroll center / contain;
        overflow: hidden;
        border: none;
		border-radius: 50%;

		@media ${device.laptop}{
			order: 1;
            align-self: center;
		}
		@media ${device.mobile}{
			width: 300px;
            height: 300px;
		}
    `;
const Image = styled.img`
		height: 500px;
		border-radius: 50%;

		@media ${device.mobile} {
			height: 400px;
		}
    `;
const Background = styled.div`
		background-color: #fff;
    `;
