import React from 'react';
import { dev } from '../../utilities/devEnv';

const OrganizerAreaListItem = ({event, prevAppl, setUserEvent, handleFeedback}) => {


	const handleSendApplication = () => {
		const url = `${dev}/events/action/send_application`;
		const data = new FormData();

		for(const key in event) {			
			if (key === 'place') {
				for (const item in event.place) {
					if (item === 'location') {
						for (const newItem in event.place.location) {
							data.append(newItem + '_location', event.place.location[newItem]);
						}						
					}
					if (typeof event.place[item] !== 'object') { 
						data.append(item + '_place', event.place[item]);
					}
				}
			}
			if (typeof event[key] !== 'object') {
				data.append('event_' + key, event[key]);
			}
		}

		const date = new Date(data.get('event_start_time'));
		const endDate = data.get('event_end_time') ? new Date(data.get('event_end_time')) : null;

		data.delete('event_start_time');
		data.append('event_start_time', '@' + Math.round(date.getTime() / 1000));
		
		if (endDate !== null) { 
			data.delete('event_end_time');
			data.append('event_end_time', '@' + Math.round(endDate.getTime() / 1000));
		} else {
			data.delete('event_end_time');
		}
		
		fetch(url, {
			method: 'POST',
			body: data
		})
			.then(res => res.json())
			.then((data) => {
				if (data.code === 200) {
					handleFeedback(data.feedback, true);
					prevAppl();
					setUserEvent([]);
				}
			});
	};

	const formatStartTime = () => {
		let newEndDate = '';
		const date = new Date(event.start_time);
		const endDate = event.end_time ? new Date(event.end_time) : '';

		if (endDate) {
			if (date.getMonth() === endDate.getMonth() 
			&& date.getDate() === endDate.getDate()) {
				return;
			} else {
				newEndDate = `- ${endDate.toLocaleDateString()} ${endDate.toLocaleTimeString()}`;
			}
		}

		return `${date.toLocaleDateString()} ${date.toLocaleTimeString()} ${endDate ? newEndDate : ''}`;
	};

	return (
		<li key={`current-${event.id}`}>
			<table>
				<thead>
					<tr>
						<th>Namn: </th>
						<th>Sted: </th>
						<th>Tid: </th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{event.name}</td>
						<td>{event.place?.name}</td>
						<td>{formatStartTime()}</td>
						<td>
							<button
								id='send-application'
								type='button'
								onClick={() => {handleSendApplication();}}
								title='Send søknad'
							>
								Send søknad
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</li>
	);
};

export default OrganizerAreaListItem;