import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { urlDev } from '../../utilities/devEnv';
import { device } from '../../utilities/breakpoints';

export default class ActivityItem extends React.Component {
	constructor (props) {
		super(props);

		this.truncateStr = this.truncateStr.bind(this);
		this.renderServiceProvider = this.renderServiceProvider.bind(this);
		this.renderInfo = this.renderInfo.bind(this);
	}

	renderServiceProvider () {
		const serviceProvider = this.props.activity.serviceProvider;
		const serviceProviderName = serviceProvider.name;
		return serviceProviderName;
	}

	renderInfo () {
		const activityInfo = this.props.activity.informations;
		let info;
		for (const item of activityInfo) {
			const infoLevel = ((item || {}).informationLevel).value;
			if (item.languageCode === this.props.language.value && infoLevel === 0.5) {
				info = item.teaser ? item.teaser : item.information;
				return this.truncateStr(info);
			}
		}
	}

	truncateStr (str) {
		return str.length > 120 ? str.substring(0, 120) + ' ...' : str;
	}

	render () {
		return (
			<Link to={`${urlDev}activity/${this.props.id}`} className='nolink activity-item'>
				<Wrapper>
					<Header>{this.props.name}</Header>
					<Provider>{this.renderServiceProvider()}</Provider>
					<Text>{this.renderInfo()}</Text>
				</Wrapper>
			</Link>
		);
	}
}

const Wrapper = styled.div`
	text-overflow: ellipsis;
	height: 100%;
	width: 100%;
        
    @media ${device.mobile}{
      padding-top: 0.2em;
      height: 100px;
      min-height: unset;
    }
  `;
const Header = styled.h3`
    text-align: left;
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media ${device.laptop} {
      font-size: 1.1em;
    }
  `;
const Provider = styled.p`
    margin: 0;
    font-weight: 500;
    font-size: 0.9em;
    line-height: 1.4;

    @media ${device.mobile} {
      line-height: 1.2;
    }
	@media ${device.smMobile} {
		line-height: 1;
	}
  `;
const Text = styled.p`
    text-align: left;
    margin: 0.5em 0 0;
    line-height: 1.4;
    font-size: 0.8em;

    @media ${device.mobile} {
      line-height: 1.2;
      margin: 0;
    }
	@media ${device.smMobile} {
		line-height: 1;
	}
  `;
