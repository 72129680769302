import React from 'react';

import CategoryButton from './CategoryButton';

export default class CategoryButtons extends React.Component {
	render () {
		const { categories } = this.props;
		const categoryButtons = [];

		const catColors = {
			art: [1, 3, 10],
			active: [4, 5, 8],
			comp: [2, 12],
			craft: [7, 9],
			lifestyle: [6, 11, 13]
		};

		const getClassNames = (id) => {
			const classNames = [
				'appbutton',
				'category-btn'
			];
			for (const type in catColors) {
				if (catColors[type].indexOf(id) !== -1) {
					classNames.push(`${type}-button`);
					break;
				}
			}
			if (!classNames.length) {
				classNames.push('other-button');
			}
			if (id === this.props.current) {
				classNames.push('current-button');
			}
			return classNames;
		};

		if (categories.length > 0) {
			categories.forEach((category) => {
				const classNames = getClassNames(category.id);
				categoryButtons.push(
					<CategoryButton
						key={category.id}
						cat={category}
						handleClick={this.props.handleClick}
						classNames={classNames.join(' ')}
					/>
				);
			});
		}

		return (
			<div className="category-buttons">
				<button
					onClick={this.props.showAll}
					className={`appbutton category-btn show-all-button ${
						this.props.allEnabled
							? 'current-button'
							: ''
					}`}
					title='Velg alle kategorier'
				>
					{this.props.allJsx}
				</button>
				{categoryButtons}
			</div>
		);
	}
}
