import React from 'react';

export default class CategoryButton extends React.Component {
	render () {
		const { cat, handleClick, classNames } = this.props;
		return (
			<button
				value={cat.id}
				onClick={handleClick}
				className={classNames}
				title='Velg kategori'
			>
				{cat.name.value}
			</button>
		);
	}
}
