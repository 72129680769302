
let dev = 'https://localhost:3000';
if (window.location.hostname === 'dev.zpirit.no') {
	dev = 'https://dev.zpirit.no/aktivitetforalle';
} else if (window.location.hostname === 'aktivitetforalle.no') {
	dev = 'https://aktivitetforalle.no';
}

let urlDev = '/';
if (window.location.hostname === 'dev.zpirit.no') {
	urlDev = '/aktivitetforalle/';
}

export {dev, urlDev};