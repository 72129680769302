import React, { useEffect, useState } from 'react';
import { dev } from '../../utilities/devEnv';

const CreateUser = () => {
	const [inputs, setInputs] = useState({});
	const [feedback, setFeedback] = useState('');
	const [error, setError] = useState(true);

	const handleSubmit = (e) => {
		e.preventDefault();
		const url = `${dev}/auth/action/create_user`;

		const data = new FormData();

		const { name, email } = inputs; 

		data.append('username', name);
		data.append('email', email);

		fetch(url, {
			method: 'POST',
			headers: {
				'x-api-key': '071bb2abbfb9d54bb7f71da320183e'
			},
			body: data
		})
			.then(res => res.json())
			.then((data) => {
				if (data.code === 200) {
					setFeedback(data.feedback);
					setError(data.error);
				}
			});
	};

	const handleInputChange = (e) => {
		const target = e.target;
		setInputs(prevState => ({...prevState, [target.name]: target.value }));
	};

	return (
		<div className='settings-create-user'>
			<h1>Lag ny bruker</h1>
			<p className={`settings-feedback ${error ? 'error' : 'success'}`}>{feedback}</p>
			<form onSubmit={(e) => handleSubmit(e)}>
				<div>
					<label htmlFor='change-username'>Brukernamn: </label>
					<input required id='change-username' name='name' type='text' onChange={(e) => handleInputChange(e)} autoComplete='username' />
				</div>
				<div>
					<label htmlFor='change-email'>E-post adresse: </label>
					<input required id='change-email' name='email' type='email' onChange={(e) => handleInputChange(e)} autoComplete='email' />
				</div>
				<button
					type='submit'
					title='Lag ny bruker'
				>
					<p>Lag bruker</p>
				</button>
			</form>
		</div>
	);
};

export default CreateUser;