import React from 'react';

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { device } from '../../utilities/breakpoints';
import { urlDev } from '../../utilities/devEnv';

export default class AddActivities extends React.Component {
	constructor (props) {
		super(props);

		this.handleClickModal = this.handleClickModal.bind(this);

		this.state = {
			enabled: false
		};
	}

	handleClickModal () {
		const enabled = this.state.enabled;
		this.setState({ enabled: !enabled });
	}

	render () {
		const addActivitiesTextSuggest = ((this.props.componentText || {}).suggest || {});

		return (
			<Wrapper>
				<TextWrapper>
					<Header>{addActivitiesTextSuggest.header}</Header>
					<span>
						{addActivitiesTextSuggest.text}
						<Link to={`${urlDev}suggest`} className={'nolink link ' + this.props.className}>
							{addActivitiesTextSuggest.button}
						</Link>
					</span>
			
				</TextWrapper>
				<TextWrapper>
					<Header>Har du eit facebook arrangement som du vil ha vist?</Header>
					<span>
						<p>
							Har du eit facebook arragement som du vil dele med andre. Send forespørsel
							om å få vise det i vår arrangement kalender.
						</p>
						<Link to={`${urlDev}arrangor`} className={'nolink link ' + this.props.className}>
							<p style={{ fontWeight: 'bold' }}>Send forespørsel</p>
						</Link>
					</span>
				</TextWrapper>
			</Wrapper>
		);
	}
}

const Wrapper = styled.section`
        display: flex;
		align-items: stretch;
		justify-content: space-around;
		margin: 4em auto 0;
		padding: 4em 4em 6em;

		@media ${device.laptop}{
			margin: 4em 0 0;
            padding: 4em 2em 6em;
		}

		@media ${device.mobile}{
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			margin: 2em auto 0;
            padding: 0 3em 3em;
		}
    `;
const TextWrapper = styled.article`
		margin: 0 1.5em;
		max-width: 400px;
		display: flex;
		flex-direction: column;

        @media ${device.laptop} {
            margin: 0 0.5em;
        }
		@media ${device.mobile}{
			width: unset;
			margin-top: 1em;

            &:last-child {
                margin-top: 4em;
            }
		}
    `;
const Header = styled.h2`
        margin-bottom: 0;
    `;
